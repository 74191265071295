import React, { useState } from 'react';
import './ContextSelectionModal.css';

const CONTEXT_SUGGESTIONS = [
  "Daily Greetings & Small Talk",
  "Ordering Food & Drinks",
  "Travel & Transportation",
  "Emergency Situations",
  "Family & Relationships", 
  "Weather & Seasons",
  "Hobbies & Free Time",
  "Work & Business",
  "Shopping & Money"
];

function ContextSelectionModal({ isOpen, onClose, onSelectContext }) {
  const [customContext, setCustomContext] = useState('');

  if (!isOpen) return null;

  return (
    <div className="context-modal-overlay">
      <div className="context-modal">
        <h2>Choose Your Learning Context</h2>
        <p>Select a situation to generate AI-powered language learning phrases:</p>
        
        <div className="context-suggestions">
          {CONTEXT_SUGGESTIONS.map((context) => (
            <button
              key={context}
              className="context-button"
              onClick={() => onSelectContext(context)}
            >
              {context}
            </button>
          ))}
        </div>

        <div className="custom-context">
          <input
            type="text"
            placeholder="Or describe your own situation..."
            value={customContext}
            onChange={(e) => setCustomContext(e.target.value)}
          />
          <button
            className="submit-context"
            onClick={() => onSelectContext(customContext)}
            disabled={!customContext.trim()}
          >
            Use Custom Context
          </button>
        </div>

        <button className="close-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ContextSelectionModal; 