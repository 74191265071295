export const badgeDetails = {
  firstMessage: {
    name: "badges.firstMessage.name",
    baseDescription: "badges.firstMessage.baseDescription",
    image: "/images/1st-message-badge.png",
    levels: {
      bronze: {
        name: "badges.firstMessage.levels.bronze.name",
        count: 1,
        next: "badges.firstMessage.levels.bronze.next",
      },
      silver: {
        name: "badges.firstMessage.levels.silver.name",
        count: 15,
        next: "badges.firstMessage.levels.silver.next",
      },
      gold: {
        name: "badges.firstMessage.levels.gold.name",
        count: 50,
        next: "badges.firstMessage.levels.gold.next",
      },
      vibranium: {
        name: "badges.firstMessage.levels.vibranium.name",
        count: 200,
        next: "badges.firstMessage.levels.vibranium.next",
      },
    },
  },
  mrWorldwide: {
    name: "badges.mrWorldwide.name",
    baseDescription: "badges.mrWorldwide.baseDescription",
    image: "/images/mr-worldwide-badge.png",
    levels: {
      bronze: {
        name: "badges.mrWorldwide.levels.bronze.name",
        count: 2,
        next: "badges.mrWorldwide.levels.bronze.next",
      },
      silver: {
        name: "badges.mrWorldwide.levels.silver.name",
        count: 4,
        next: "badges.mrWorldwide.levels.silver.next",
      },
      gold: {
        name: "badges.mrWorldwide.levels.gold.name",
        count: 6,
        next: "badges.mrWorldwide.levels.gold.next",
      },
      vibranium: {
        name: "badges.mrWorldwide.levels.vibranium.name",
        count: 8,
        next: "badges.mrWorldwide.levels.vibranium.next",
      },
    },
  },
  connector: {
    name: "badges.connector.name",
    baseDescription: "badges.connector.baseDescription",
    image: "/images/connector-badge.png",
    levels: {
      bronze: {
        name: "badges.connector.levels.bronze.name",
        count: 10,
        next: "badges.connector.levels.bronze.next",
      },
      silver: {
        name: "badges.connector.levels.silver.name",
        count: 30,
        next: "badges.connector.levels.silver.next",
      },
      gold: {
        name: "badges.connector.levels.gold.name",
        count: 50,
        next: "badges.connector.levels.gold.next",
      },
      vibranium: {
        name: "badges.connector.levels.vibranium.name",
        count: 100,
        next: "badges.connector.levels.vibranium.next",
      },
    },
  },
  consistentCapper: {
    name: "badges.consistentCapper.name",
    baseDescription: "badges.consistentCapper.baseDescription",
    image: "/images/speakeasylogo.png",
    levels: {
      bronze: {
        name: "badges.consistentCapper.levels.bronze.name",
        count: 3,
        next: "badges.consistentCapper.levels.bronze.next",
      },
      silver: {
        name: "badges.consistentCapper.levels.silver.name",
        count: 10,
        next: "badges.consistentCapper.levels.silver.next",
      },
      gold: {
        name: "badges.consistentCapper.levels.gold.name",
        count: 25,
        next: "badges.consistentCapper.levels.gold.next",
      },
      vibranium: {
        name: "badges.consistentCapper.levels.vibranium.name",
        count: 100,
        next: "badges.consistentCapper.levels.vibranium.next",
      },
    },
  },
  friendshipGoals: {
    name: "badges.friendshipGoals.name",
    baseDescription: "badges.friendshipGoals.baseDescription",
    image: "/images/friendship-badge.png",
    levels: {
      bronze: {
        name: "badges.friendshipGoals.levels.bronze.name",
        count: 3,
        next: "badges.friendshipGoals.levels.bronze.next",
      },
      silver: {
        name: "badges.friendshipGoals.levels.silver.name",
        count: 10,
        next: "badges.friendshipGoals.levels.silver.next",
      },
      gold: {
        name: "badges.friendshipGoals.levels.gold.name",
        count: 25,
        next: "badges.friendshipGoals.levels.gold.next",
      },
      vibranium: {
        name: "badges.friendshipGoals.levels.vibranium.name",
        count: 50,
        next: "badges.friendshipGoals.levels.vibranium.next",
      },
    },
  },
  MaxVerstappen: {
    name: "badges.MaxVerstappen.name",
    baseDescription: "badges.MaxVerstappen.baseDescription",
    image: "/images/max_verstappen_badge.png",
    levels: {
      bronze: {
        name: "badges.MaxVerstappen.levels.bronze.name",
        count: 10,
        next: "badges.MaxVerstappen.levels.bronze.next",
      },
      silver: {
        name: "badges.MaxVerstappen.levels.silver.name",
        count: 25,
        next: "badges.MaxVerstappen.levels.silver.next",
      },
      gold: {
        name: "badges.MaxVerstappen.levels.gold.name",
        count: 100,
        next: "badges.MaxVerstappen.levels.gold.next",
      },
      vibranium: {
        name: "badges.MaxVerstappen.levels.vibranium.name",
        count: 300,
        next: "badges.MaxVerstappen.levels.vibranium.next",
      },
    },
  },
};

export const getTranslatedBadgeDetails = (t) => {
  const translatedBadges = {};
  
  Object.entries(badgeDetails).forEach(([badgeKey, badge]) => {
    translatedBadges[badgeKey] = {
      ...badge,
      name: t(badge.name),
      baseDescription: t(badge.baseDescription),
      levels: Object.entries(badge.levels).reduce((acc, [levelKey, level]) => ({
        ...acc,
        [levelKey]: {
          ...level,
          name: t(level.name),
          next: t(level.next),
        },
      }), {}),
    };
  });
  
  return translatedBadges;
};
