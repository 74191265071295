import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './SpeakeasyProModal.css';
import { FaSpotify, FaYoutube } from 'react-icons/fa'; // Import icons

const SpeakeasyProModal = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      setIsAnimating(false);
      document.body.style.overflow = 'auto'; // Restore scrolling
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t("SpeakeasyProModal.speakeasy.pro")}
      className={`modal speakeasy-pro-modal ${isAnimating ? 'open' : ''}`}
      overlayClassName="overlay"
    >
      <button className="close-modal-button" onClick={onRequestClose}>x</button>
      <div className="pro-content">
        <h2>{t("SpeakeasyProModal.elevate.your.language.learning.with")} <span className="highlight">{t("SpeakeasyProModal.speakeasy.pro")}</span></h2>
        <img 
          src="/images/home-page-video-chat.png" 
          alt={t("SpeakeasyProModal.speakeasy.pro.video.chat")} 
          className="pro-image"
        />
        <div className="pro-pricing">
          <span className="pro-price">{t("SpeakeasyProModal.499")}</span>
          <span className="pro-period">{t("SpeakeasyProModal.month")}</span>
        </div>
        <ul className="pro-features">
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("SpeakeasyProModal.exclusive.premium.content")}<span className="feature-icons">
                <FaSpotify className="icon spotify" />
                <FaYoutube className="icon youtube" />
              </span>
              <span className="feature-tooltip">{t("SpeakeasyProModal.integrate.spotify.to.learn.through.music.and.get.personalized.youtube.video.recommendations")}</span>
            </span>
          </li>
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("SpeakeasyProModal.unlimited.video.chat.capabilities")}<span className="feature-tooltip">{t("SpeakeasyProModal.practice.with.language.partners.worldwide.with.no.time.restrictions")}</span>
            </span>
          </li>
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("SpeakeasyProModal.collaborative.learning.tools")}<span className="feature-tooltip">{t("SpeakeasyProModal.engage.in.interactive.exercises.and.realtime.document.sharing")}</span>
            </span>
          </li>
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("SpeakeasyProModal.adfree.experience")}<span className="feature-tooltip">{t("SpeakeasyProModal.enjoy.uninterrupted.immersive.learning.sessions")}</span>
            </span>
          </li>
        </ul>
        <button className="upgrade-button">{t("SpeakeasyProModal.coming.soon")}</button>
      </div>
    </Modal>
  );
};

export default SpeakeasyProModal;
