import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './SelectLanguages.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',
};

const SelectLanguages = () => {
  const navigate = useNavigate();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [learnersCount, setLearnersCount] = useState({});

  useEffect(() => {
    fetchLearnersCount();
  }, []);

  const fetchLearnersCount = async () => {
    try {
      const response = await fetch(`${apiUrl}/language_learners_count`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (data.success) {
        setLearnersCount(data.learners_count);
      }
    } catch (error) {
      console.error('Error fetching learners count:', error);
    }
  };

  const toggleLanguageSelection = (language) => {
    setSelectedLanguages(prevState =>
      prevState.includes(language)
        ? prevState.filter(l => l !== language)
        : [...prevState, language]
    );
  };

  const handleContinue = () => {
    navigate('/register', { state: { selectedLanguages } });
  };

  return (
    <div className="select-language-page">
      <Link to="/home">
        <img src="/images/speakeasylogo.png" alt="SpeakEasy Logo" className="sign-in-logo" />
      </Link>
      <h1 className="select-language-header">Select Your Languages</h1>
      <p className="select-language-instructions">You can always choose more later!</p>
      <div className="select-language-container">
        {Object.keys(languageImages).map(language => (
          <div
            key={language}
            className={`select-language-card ${selectedLanguages.includes(language) ? 'selected' : ''}`}
            onClick={() => toggleLanguageSelection(language)}
          >
            <img src={languageImages[language]} alt={language} className="select-language-image" />
            <div className="select-language-name">
              {language}
              <div className="select-language-status">
                {learnersCount[language] || 0} learners
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="home-get-started-button" onClick={handleContinue}>Continue</button>
    </div>
  );
};

export default SelectLanguages;
