import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy.js CSS
import './Club.css';
import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext


const languagesList = [
  { name: 'Spanish', src: '/images/mexico_flag.png' },
  { name: 'Japanese', src: '/images/icon_flag-ja.svg' },
  { name: 'Portuguese', src: '/images/icon_flag-pt.svg' },
  { name: 'French', src: '/images/icon_flag-fr.svg' },
  { name: 'German', src: '/images/icon_flag-de.svg' },
  { name: 'Italian', src: '/images/icon_flag-it.svg' },
  { name: 'Mandarin', src: '/images/icon_flag-zh.svg' },
  { name: 'Arabic', src: '/images/icon_flag-ar.svg' },
  { name: 'Cantonese', src: '/images/icon_flag-hk.png' },
  { name: 'Korean', src: '/images/icon_flag-ko.svg' },
  { name: 'Russian', src: '/images/icon_flag-ru.svg' },
  { name: 'Tagalog', src: '/images/icon_flag_pp.png' },
];

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function Club() {
  const { user } = useContext(AuthContext); // Get the user from AuthContext
  const [meetings, setMeetings] = useState([]);
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    language_topic: '',
    location: ''
  });
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [showTippy, setShowTippy] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [showLoadingText, setShowLoadingText] = useState(false); // Add state for showing loading text
  const [visibleLanguages, setVisibleLanguages] = useState(languagesList.slice(0, 6)); // State for visible languages
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index for language display
  const navigate = useNavigate();

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setShowLoadingText(true);
    }, 500); // Delay before showing loading text

    fetch(`${apiUrl}/meetings`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setMeetings(data.sort((a, b) => new Date(a.date) - new Date(b.date))); // Sort by date ascending
        setLoading(false);
        clearTimeout(loadingTimeout); // Clear the timeout when loading is done
        setShowLoadingText(false); // Ensure loading text is hidden
      })
      .catch(error => {
        console.error('Error fetching meetings:', error);
        setLoading(false);
        clearTimeout(loadingTimeout);
        setShowLoadingText(false);
      });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${apiUrl}/meetings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        setMeetings([data.meeting, ...meetings].sort((a, b) => new Date(a.date) - new Date(b.date))); // Add new meeting and sort by date ascending
        setFormData({
          date: '',
          time: '',
          language_topic: '',
          location: ''
        });
      })
      .catch(error => console.error('Error creating meeting:', error));
  };

  const toggleAttendance = (index, meetingId) => {
    if (!user || !user.id) {
      console.error('User not logged in');
      return;
    }

    fetch(`${apiUrl}/meetings/${meetingId}/attend`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: user.id })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const updatedMeetings = meetings.map((meeting, i) => (
          i === index ? { ...meeting, attendees_info: data.attendees } : meeting
        ));
        setMeetings(updatedMeetings);
      })
      .catch(error => console.error('Error updating attendance:', error));
  };

  const toggleLanguageSelection = (language) => {
    setSelectedLanguages(prevState =>
      prevState.includes(language)
        ? prevState.filter(l => l !== language)
        : [...prevState, language]
    );
  };

  const handleGetStarted = () => {
    if (selectedLanguages.length === 0) {
      setShowTippy(true);
      setTimeout(() => setShowTippy(false), 3000); // Hide the tooltip after 3 seconds
    } else {
      navigate('/register', { state: { selectedLanguages } });
    }
  };

  const handleNextLanguages = () => {
    const nextIndex = currentIndex + 6;
    if (nextIndex < languagesList.length) {
      setVisibleLanguages(languagesList.slice(nextIndex, nextIndex + 6));
      setCurrentIndex(nextIndex);
    }
  };

  const handlePreviousLanguages = () => {
    const prevIndex = currentIndex - 6;
    if (prevIndex >= 0) {
      setVisibleLanguages(languagesList.slice(prevIndex, prevIndex + 6));
      setCurrentIndex(prevIndex);
    }
  };

  // Add placeholder items to fill the empty space

  if (loading && showLoadingText) {
    return <div>Loading...</div>; // Show a loading message or spinner
  }

  const getProfilePictureSrc = (profilePicture) => {
    if (profilePicture.startsWith('http')) {
      return profilePicture;
    }
    return `${apiUrl}/${profilePicture}`;
  };

  return (
    <div className="club-page fade-in">
      <h2 className="centered-text fade-in">Upcoming Club Meetings</h2>
      <div className="meetings-grid fade-in">
        {meetings.map((meeting, index) => (
          <div key={index} className="meeting-item fade-in">
            <div className="meeting-header fade-in">
              <h3>{meeting.language_topic}</h3>
              <span>{meeting.date}</span>
            </div>
            <div className="meeting-info fade-in">
              <div>
                <span>{meeting.time}</span>
              </div>
              <div>
                <span>{meeting.location}</span>
              </div>
            </div>
            <div className="meeting-actions fade-in">
              <button
                className={`attendance-button fade-in ${user && meeting.attendees_info?.some(attendee => attendee.email === user.email) ? 'added' : ''}`}
                onClick={() => toggleAttendance(index, meeting.id)}
              >
                {user && meeting.attendees_info?.some(attendee => attendee.email === user.email) ? 'Added' : 'Count me in!'}
              </button>
            </div>
            <div className="attendees-list fade-in">
              <span>→ <strong>Currently Going:</strong> {meeting.attendees_info.length > 0 ? meeting.attendees_info.map((attendee, idx) => (
                <Tippy
                  key={idx}
                  content={<span>{attendee.display_name}</span>}
                  placement="top"
                >
                  <img
                    src={getProfilePictureSrc(attendee.profile_picture)}
                    alt={attendee.display_name}
                    className="attendee-profile-pic-club fade-in"
                  />
                </Tippy>
              )) : 'No one yet'}</span>
            </div>
          </div>
        ))}
  
      </div>

      {!user && (
        <>
          <div className="get-started-container fade-in">
            <Tippy content="Please select your languages below!" visible={showTippy} animation="scale" arrow={true}>
              <button className="get-started-button fade-in" onClick={handleGetStarted}>Get Started</button>
            </Tippy>
          </div>

          <div className="language-section fade-in">
            <h2 className="fade-in">I want to learn...</h2>
            <div className="languages-navigation fade-in">
              <button onClick={handlePreviousLanguages} disabled={currentIndex === 0}>{"<"}</button>
              <div className="languages-container fade-in">
                {visibleLanguages.map(language => (
                  <div
                    key={language.name}
                    className={`language-item fade-in ${selectedLanguages.includes(language.name) ? 'selected' : ''}`}
                    onClick={() => toggleLanguageSelection(language.name)}
                  >
                    <img src={language.src} alt={language.name} className="fade-in" />
                    <p className="fade-in">{language.name}</p>
                    {selectedLanguages.includes(language.name) && <span className="checkmark fade-in">✔️</span>}
                  </div>
                ))}
              </div>
              <button onClick={handleNextLanguages} disabled={currentIndex + 6 >= languagesList.length}>{">"}</button>
            </div>
          </div>
        </>
      )}

      <div className="create-meeting-container fade-in">
        <h2 className="fade-in">Create a New Meeting</h2>
        <form onSubmit={handleSubmit} className="meeting-form fade-in">
          <input
            type="text"
            name="date"
            placeholder="Date"
            value={formData.date}
            onChange={handleChange}
            required
            className="fade-in"
          />
          <input
            type="text"
            name="time"
            placeholder="Time"
            value={formData.time}
            onChange={handleChange}
            required
            className="fade-in"
          />
          <input
            type="text"
            name="language_topic"
            placeholder="Language/Topic"
            value={formData.language_topic}
            onChange={handleChange}
            required
            className="fade-in"
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.location}
            onChange={handleChange}
            required
            className="fade-in"
          />
          <button type="submit" className="fade-in">Create Meeting</button>
        </form>
      </div>
    </div>
  );
}

export default Club;
