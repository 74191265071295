import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faChartBar, 
  faMessage, 
  faEarthAmericas, 
  faUser 
} from '@fortawesome/free-solid-svg-icons';
import './MobileNavbar.css';

const MobileNavbar = ({ user }) => {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const isActive = (path) => location.pathname === path;

  const getProfilePictureSrc = (profilePicture) => {
    return profilePicture?.startsWith('http') 
      ? profilePicture 
      : `${apiUrl}/${profilePicture}`;
  };

  const isHomePage = location.pathname === '/';

  if (isHomePage) {
    return null;
  }

  return (
    <div className="mobile-nav-container">
      <div className="mobile-menu">
        {user ? (
          <>
            <Link 
              to="/dashboard" 
              className={`mobile-nav-link ${isActive('/dashboard') ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faChartBar} className="icon" />
            </Link>
            <Link 
              to="/chatrooms" 
              className={`mobile-nav-link ${isActive('/chatrooms') ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faMessage} className="icon" />
            </Link>
            <Link 
              to="/discover" 
              className={`mobile-nav-link ${isActive('/discover') ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faEarthAmericas} className="icon" />
            </Link>
            <Link 
              to="/account" 
              className={`mobile-nav-link ${isActive('/account') ? 'active' : ''}`}
            >
              <img 
                src={getProfilePictureSrc(user.profile_picture)} 
                alt={user.display_name}
                className="mobile-profile-pic"
              />
            </Link>
          </>
        ) : (
          <Link to="/signin" className="mobile-nav-link">
            <FontAwesomeIcon icon={faUser} className="icon" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default MobileNavbar; 