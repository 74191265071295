// ProgressBar.js
import React from 'react';
import './ProgressBar.css'; // Make sure to style it as needed

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="progress-fill" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
