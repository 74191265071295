import React, { useState} from 'react';
import Modal from 'react-modal';
import './FeedbackForm.css';
import { useTranslation } from 'react-i18next';

const FeedbackForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [category, setCategory] = useState('general');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies if user is logged in
        body: JSON.stringify({
          feedback,
          email,
          category
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsOpen(false);
          setFeedback('');
          setEmail('');
          setCategory('general');
          setIsSubmitted(false);
        }, 3000);
      } else {
        throw new Error(data.message || 'Failed to submit feedback');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error submitting feedback:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button 
        className="feedback-button"
        onClick={handleButtonClick}
      >
        <i className="fa-regular fa-comments"></i>
        <span className="button-text">{t('feedback.button')}</span>
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className={`modal feedback-modal ${isOpen ? 'open' : ''}`}
        overlayClassName="feedback-overlay"
      >
        <button 
          className="close-modal-button"
          onClick={() => setIsOpen(false)}
        >
          &times;
        </button>

        <div className="feedback-content">
          <h2>{t('feedback.title')}</h2>
        
          
          
          {isSubmitted ? (
            <div className="feedback-success">
              <p>{t('feedback.success')}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {error && (
                <div className="feedback-error">
                  <p>{error}</p>
                </div>
              )}
              
              <div className="form-group">
                <label>{t('feedback.categoryLabel')}</label>
                <select 
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  <option value="general">{t('feedback.categories.general')}</option>
                  <option value="bug">{t('feedback.categories.bug')}</option>
                  <option value="feature">{t('feedback.categories.feature')}</option>
                  <option value="improvement">{t('feedback.categories.improvement')}</option>
                  <option value="suggestion">{t('feedback.categories.suggestion')}</option>
                </select>
              </div>

              <div className="form-group">
                <label>{t('feedback.emailLabel')}</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('feedback.emailPlaceholder')}
                />
              </div>

              <div className="form-group">
                <label>{t('feedback.feedbackLabel')}</label>
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder={t('feedback.feedbackPlaceholder')}
                  required
                  rows="4"
                />
              </div>

              <button 
                type="submit" 
                className="submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? t('feedback.submitting') : t('feedback.submit')}
              </button>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FeedbackForm;
