import React from 'react';
import Modal from 'react-modal';
import '../Dashboard/WelcomeModal.css'; // Reuse the same styling as WelcomeModal

Modal.setAppElement('#root'); // This is important for screen readers

const VideoChatModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="welcome-modal" // Reuse the WelcomeModal styles
      overlayClassName="welcome-modal-overlay"
    >
      <h2 className="modal-title">Welcome to Speakeasy Video Chat!</h2>
      <p className="modal-text">
        We're so excited to have you! Please be respectful and enjoy meeting other learners.
      </p>
      <div className="modal-buttons">
        <button className="modal-button" onClick={onClose}>Got it!</button>
      </div>
    </Modal>
  );
};

export default VideoChatModal;
