// src/routes/SpotifyPlayer.tsx
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import './SpotifyPlayer.css';
import { PlaybackContext } from './PlaybackContext'; // Corrected import path
import { useTranslation } from 'react-i18next';

const CLIENT_ID = '6d48623f62414db29028b54e3b0e7a28'; // Replace with your Spotify Client ID
const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI || 'http://localhost:3000/spotify-player';
const SCOPES = [
  'streaming',
  'user-read-email',
  'user-read-private',
  'user-read-playback-state',
  'user-modify-playback-state',
  'user-read-currently-playing',
].join(' ');

// Replace with your predefined playlist URI (Spotify URI format)
const PREDEFINED_PLAYLIST_URI = 'spotify:playlist:45GzgV6QdDD5fXBN4i3U0g';

const SPOTIFY_AUTH_URL = `https://accounts.spotify.com/authorize?response_type=token&client_id=${CLIENT_ID}&scope=${encodeURIComponent(SCOPES)}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&show_dialog=true`;

const PLAYLISTS = {
  Spanish: 'spotify:playlist:45GzgV6QdDD5fXBN4i3U0g',
  Japanese: 'spotify:playlist:05kVxbzpa7Ce49b7JN4VKx',
  Portuguese: 'spotify:playlist:2rxPVzeD8XpTQaKoKk9Jgf',
  French: 'spotify:playlist:37i9dQZEVXbIPWwFssbupI',
  German: 'spotify:playlist:7rV9YlnPaswNlbEaaSg6IT',
  Italian: 'spotify:playlist:0ZpYVvLjtMReufEoGwtvqC',
  Mandarin: 'spotify:playlist:1BbPTZ8Kkyiw4iz6DSwpy7',
  Arabic: 'spotify:playlist:2ydDDe6Sthb6BTxEeGVRcL',
  Cantonese: 'spotify:playlist:5SW7lbMOx6RY2DsEr3D1go',
  Korean: 'spotify:playlist:2EoheVFjqIxgJMb8VnDRtZ',
  Russian: 'spotify:playlist:7lMt7i0RQmjiM8hfZys4SQ',
  Tagalog: 'spotify:playlist:37i9dQZF1DX4olOMiqFeqU'
};

const SpotifyPlayer: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const { currentTrack, setCurrentTrack, isPlaying, setIsPlaying } = useContext(PlaybackContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('spotify_token'));
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>(PREDEFINED_PLAYLIST_URI);
  const { t } = useTranslation();

  // Extract access token from URL hash
  const getTokenFromURL = (): string | null => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    return params.get('access_token');
  };

  // Function to make Spotify API requests
  const makeSpotifyRequest = useCallback(
    async (url: string, method: string = 'GET', body: any = null) => {
      if (!token) return null;

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: body ? JSON.stringify(body) : null,
      });

      if (response.status === 401) {
        // Token expired
        setIsAuthenticated(false);
        localStorage.removeItem('spotify_token');
        return null;
      }

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Spotify API request failed: ${response.status} ${response.statusText} - ${errorText}`);
      }

      return response;
    },
    [token, setIsAuthenticated]
  );

  // Transfer playback to the Web Playback SDK and start the predefined playlist
  const transferPlaybackHere = useCallback(
    async (device_id: string) => {
      try {
        console.log('Attempting to transfer playback to device:', device_id);
        await makeSpotifyRequest('https://api.spotify.com/v1/me/player', 'PUT', {
          device_ids: [device_id],
          play: false,
        });
        console.log('Playback transferred successfully');
      } catch (error) {
        console.error('Error in transferPlaybackHere:', error);
      }
    },
    [makeSpotifyRequest]
  );

  // Add function to change playlist
  const changePlaylist = useCallback(async (playlistUri: string) => {
    try {
      setSelectedPlaylist(playlistUri);
      await makeSpotifyRequest('https://api.spotify.com/v1/me/player/play', 'PUT', {
        context_uri: playlistUri,
        offset: { position: 0 },
        position_ms: 0,
      });
      setIsPlaying(true);
      console.log('Playlist changed and started playing');
    } catch (error) {
      console.error('Error changing playlist:', error);
    }
  }, [makeSpotifyRequest, setIsPlaying]);

  // Initialize token from URL or localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem('spotify_token');
    const urlToken = getTokenFromURL();

    if (urlToken) {
      setToken(urlToken);
      setIsAuthenticated(true);
      localStorage.setItem('spotify_token', urlToken);
      window.location.hash = '';
    } else if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    console.log('Initialized Token:', token);
  }, [token]);

  // Initialize Spotify SDK
  useEffect(() => {
    if (token) {
      console.log('Initializing Spotify SDK with token:', token);
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      script.async = true;
      script.onload = () => {
        console.log('Spotify SDK script loaded successfully');
      };
      script.onerror = () => {
        console.error('Failed to load Spotify Web Playback SDK');
      };
      document.body.appendChild(script);

      // Initialize the Spotify Player once the SDK is ready
      window.onSpotifyWebPlaybackSDKReady = () => {
        console.log('Spotify Web Playback SDK is ready');
        const spotifyPlayer = new window.Spotify.Player({
          name: 'Speakeasy Spotify Player',
          getOAuthToken: (cb: (token: string) => void) => {
            cb(token);
          },
          volume: 0.5,
        });

        // Error handling
        spotifyPlayer.addListener('initialization_error', ({ message }: { message: string }) => {
          console.error('Initialization Error:', message);
        });
        spotifyPlayer.addListener('authentication_error', ({ message }: { message: string }) => {
          console.error('Authentication Error:', message);
          setIsAuthenticated(false);
          localStorage.removeItem('spotify_token');
        });
        spotifyPlayer.addListener('account_error', ({ message }: { message: string }) => {
          console.error('Account Error:', message);
        });
        spotifyPlayer.addListener('playback_error', ({ message }: { message: string }) => {
          console.error('Playback Error:', message);
        });

        // Playback status updates
        spotifyPlayer.addListener('player_state_changed', (state: Spotify.PlaybackState | null) => {
          console.log('Player State Changed:', state);
          if (state) {
            setCurrentTrack(state.track_window.current_track);
            setIsPlaying(!state.paused);
            console.log('Current Track:', state.track_window.current_track);
            console.log('Is Playing:', !state.paused);
          }
        });

        // Ready
        spotifyPlayer.addListener('ready', ({ device_id }: { device_id: string }) => {
          console.log('Spotify Player Ready, Device ID:', device_id);
          transferPlaybackHere(device_id);
        });

        // Not Ready
        spotifyPlayer.addListener('not_ready', ({ device_id }: { device_id: string }) => {
          console.log('Player Not Ready, Device ID:', device_id);
        });

        // Connect to the player!
        spotifyPlayer.connect().then((success: boolean) => {
          if (success) {
            console.log('Connected to Spotify Player');
          } else {
            console.error('Failed to connect to Spotify Player');
          }
        });

        // Cleanup on unmount
        return () => {
          console.log('Disconnecting Spotify Player');
          spotifyPlayer.disconnect();
          document.body.removeChild(script);
        };
      };
    }
  }, [token, transferPlaybackHere, setCurrentTrack, setIsPlaying]);

  // Play/Pause toggle
  const togglePlay = useCallback(async () => {
    try {
      if (isPlaying) {
        await makeSpotifyRequest('https://api.spotify.com/v1/me/player/pause', 'PUT');
        setIsPlaying(false);
      } else {
        await makeSpotifyRequest('https://api.spotify.com/v1/me/player/play', 'PUT');
        setIsPlaying(true);
      }
      console.log('Playback toggled');
    } catch (error) {
      console.error('Error toggling playback:', error);
    }
  }, [makeSpotifyRequest, isPlaying, setIsPlaying]);

  // Skip to next track
  const skipToNext = useCallback(async () => {
    try {
      await makeSpotifyRequest('https://api.spotify.com/v1/me/player/next', 'POST');
      console.log('Skipped to next track');
    } catch (error) {
      console.error('Error skipping to next track:', error);
    }
  }, [makeSpotifyRequest]);

  // Skip to previous track
  const skipToPrevious = useCallback(async () => {
    try {
      await makeSpotifyRequest('https://api.spotify.com/v1/me/player/previous', 'POST');
      console.log('Skipped to previous track');
    } catch (error) {
      console.error('Error skipping to previous track:', error);
    }
  }, [makeSpotifyRequest]);

  // Handle re-authentication
  const handleReAuthenticate = () => {
    window.location.href = SPOTIFY_AUTH_URL;
  };

  // Start Playback
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div className="spotify-player-page">
      {!isAuthenticated ? (
        <>
          <div className="spotify-auth-logo-container">
            <img src="/images/spotify-text.png" alt={t('spotify.logo')} className="spotify-auth-logo" />
          </div>
          <div className="spotify-auth-container">
            <h2 className="spotify-auth-heading">{t('spotify.auth.heading')}</h2>
            <div className="spotify-auth-message">
              <p>{t('spotify.auth.connectMessage')}</p>
              <ul>
                <li>{t('spotify.auth.features.playlists')}</li>
                <li>{t('spotify.auth.features.lyrics')}</li>
                <li>{t('spotify.auth.features.listening')}</li>
              </ul>
            </div>
            <a href={SPOTIFY_AUTH_URL} className="spotify-auth-button">
              {t('spotify.auth.connectButton')}
            </a>
            <div className="spotify-auth-note">
            </div>
          </div>
        </>
      ) : (
        <div className="spotify-player-container">
          <div className="spotify-player-playlist-selector">
            <label htmlFor="playlist-select" className="spotify-player-playlist-label">
              {t('spotify.player.selectPlaylist')}:
            </label>
            <select
              id="playlist-select"
              className="spotify-player-playlist-select"
              value={selectedPlaylist}
              onChange={(e) => changePlaylist(e.target.value)}
            >
              {Object.entries(PLAYLISTS).map(([name, uri]) => (
                <option key={uri} value={uri}>
                  {t(`languages.${name.toLowerCase()}`)}
                </option>
              ))}
            </select>
          </div>
          {currentTrack ? (
            <div className="spotify-player-widget">
              <img
                src={currentTrack.album.images[0]?.url || ''}
                alt={t('spotify.player.albumArt')}
                className="spotify-player-album-art"
              />
              <div className="spotify-player-track-info">
                <p className="spotify-player-track-name">{currentTrack.name}</p>
                <p className="spotify-player-artist-name">
                  {currentTrack.artists.map((artist: { name: string }) => artist.name).join(', ')}
                </p>
              </div>
              <div className="spotify-player-controls">
                <button 
                  onClick={skipToPrevious} 
                  className="spotify-player-button"
                  title={t('spotify.player.controls.previous')}
                >
                  <FontAwesomeIcon icon={faStepBackward} />
                </button>
                <button 
                  onClick={togglePlay} 
                  className="spotify-player-button spotify-player-button-play-pause"
                  title={t(`spotify.player.controls.${isPlaying ? 'pause' : 'play'}`)}
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </button>
                <button 
                  onClick={skipToNext} 
                  className="spotify-player-button"
                  title={t('spotify.player.controls.next')}
                >
                  <FontAwesomeIcon icon={faStepForward} />
                </button>
              </div>
            </div>
          ) : (
            <p>{t('spotify.player.noTrack')}</p>
          )}
          <button onClick={handleReAuthenticate} className="spotify-player-reauthenticate">
            {t('spotify.auth.reauthenticate')}
          </button>
        </div>
      )}
    </div>
  );
};

export default SpotifyPlayer;
