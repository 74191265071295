import React, { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStepForward, faStepBackward } from '@fortawesome/free-solid-svg-icons';
import './NavbarSpotifyPlayer.css';
import { PlaybackContext } from '../routes/Spotify/PlaybackContext';

interface SpotifyDevice {
  id: string;
  name: string;
}

interface SpotifyDevicesResponse {
  devices: SpotifyDevice[];
}

const NavbarSpotifyPlayer: React.FC = () => {
  const { currentTrack, isPlaying, setIsPlaying } = useContext(PlaybackContext);
  const navigate = useNavigate();

  const togglePlayPause = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const token = localStorage.getItem('spotify_token');
      if (!token) return;

      const endpoint = isPlaying ? 'pause' : 'play';
      const response = await fetch(`https://api.spotify.com/v1/me/player/${endpoint}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) {
        setIsPlaying(!isPlaying);
      } else if (response.status === 404) {
        // If no active device is found, try to start playback on this device
        const devicesResponse = await fetch('https://api.spotify.com/v1/me/player/devices', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        const devices: SpotifyDevicesResponse = await devicesResponse.json();

        const thisDevice = devices.devices.find((d: SpotifyDevice) => d.name === 'Speakeasy Spotify Player');
        if (thisDevice) {
          await fetch('https://api.spotify.com/v1/me/player', {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              device_ids: [thisDevice.id],
              play: true,
            }),
          });
          setIsPlaying(true);
        }
      }
    } catch (error) {
      console.error('Error toggling play/pause:', error);
    }
  }, [isPlaying, setIsPlaying]);

  const skipToNext = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const token = localStorage.getItem('spotify_token');
      if (!token) return;

      await fetch('https://api.spotify.com/v1/me/player/next', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error skipping to next track:', error);
    }
  }, []);

  const skipToPrevious = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const token = localStorage.getItem('spotify_token');
      if (!token) return;

      await fetch('https://api.spotify.com/v1/me/player/previous', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error skipping to previous track:', error);
    }
  }, []);

  const navigateToSpotifyPlayer = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    navigate('/spotify-player');
  }, [navigate]);

  if (!currentTrack) {
    return null; // Don't render anything if no track is available
  }

  return (
    <div className="navbar-spotify-player" onClick={navigateToSpotifyPlayer}>
      <img src={currentTrack.album.images[2]?.url || ''} alt="Album Art" className="navbar-album-art" />
      <div className="navbar-track-info">
        <p className="navbar-track-name">{currentTrack.name}</p>
        <p className="navbar-artist-name">
          {currentTrack.artists.map((artist: { name: string }) => artist.name).join(', ')}
        </p>
      </div>
      <div className="navbar-spotify-controls">
        <button onClick={skipToPrevious} className="navbar-spotify-control-button">
          <FontAwesomeIcon icon={faStepBackward} />
        </button>
        <button onClick={togglePlayPause} className="navbar-spotify-control-button play-pause">
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
        <button onClick={skipToNext} className="navbar-spotify-control-button">
          <FontAwesomeIcon icon={faStepForward} />
        </button>
      </div>
    </div>
  );
};

export default NavbarSpotifyPlayer;
