import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";
import { AuthContext } from "../../contexts/AuthContext";
import "./ChatRooms.css";
import SearchModal from "./SearchModal";
import DirectMessagesList from "./DirectMessagesList";
import createConversation from "../../utils/createConversation"; // Import the helper
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: "/images/mexico_flag.png",
  Japanese: "/images/icon_flag-ja.svg",
  Portuguese: "/images/icon_flag-pt.svg",
  French: "/images/icon_flag-fr.svg",
  German: "/images/icon_flag-de.svg",
  Italian: "/images/icon_flag-it.svg",
  Mandarin: "/images/icon_flag-zh.svg",
  Arabic: "/images/icon_flag-ar.svg",
  Cantonese: "/images/icon_flag-hk.png",
  Korean: "/images/icon_flag-ko.svg",
  Russian: "/images/icon_flag-ru.svg",
  Tagalog: "/images/icon_flag_pp.png",
  Greek: "/images/icon_flag_gr.png",
  Hindi: "/images/icon_flag_in.png",
};

// Utility function to create a sorted chatRoomId
// const createChatRoomId = (userId1, userId2) => {
//   return [userId1, userId2].sort((a, b) => a - b).join('_');
// };

const getTranslatedLanguage = (language, t) => {
  return t(`languages.${language.toLowerCase()}`);
};

const ChatRooms = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userCounts, setUserCounts] = useState({});
  const [userLanguages, setUserLanguages] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [existingConversations, setExistingConversations] = useState([]);
  const [isTourActive, setIsTourActive] = useState(false);
  const [isLoadingCounts, setIsLoadingCounts] = useState(true);
  const { t } = useTranslation();

  // Fetch user's language interests
  const fetchUserLanguages = useCallback(async () => {
    if (user && user.id) {
      try {
        const response = await fetch(
          `${apiUrl}/user/interests?user_id=${user.id}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        const data = await response.json();
        if (data.success) {
          setUserLanguages(data.interests);
        } else {
          console.error("Failed to fetch interests:", data.message);
        }
      } catch (error) {
        console.error("Error fetching user languages:", error);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchUserLanguages();
  }, [fetchUserLanguages]);

  // Fetch member counts for each language chat room
  const fetchUserCounts = useCallback(async () => {
    setIsLoadingCounts(true);
    const counts = {};
    for (const language of userLanguages) {
      try {
        const messagesCollection = collection(
          firestore,
          "chats",
          language.toLowerCase(),
          "messages"
        );
        const q = query(messagesCollection);
        const querySnapshot = await getDocs(q);
        
        const uniqueUsers = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.user_id) {
            uniqueUsers.add(data.user_id);
          }
        });
        
        counts[language] = uniqueUsers.size;
      } catch (error) {
        console.error(`Error fetching member count for ${language}:`, error);
        counts[language] = 0;
      }
    }
    setUserCounts(counts);
    setIsLoadingCounts(false);
  }, [userLanguages]);

  useEffect(() => {
    if (userLanguages.length > 0) {
      fetchUserCounts();
    }
  }, [fetchUserCounts, userLanguages]);

  // Fetch existing conversation
  const fetchExistingConversations = useCallback(async () => {
    if (user && user.id) {
      const conversationsRef = collection(firestore, "conversations");
      const q = query(
        conversationsRef,
        where("participants", "array-contains", user.id.toString())
      );

      try {
        const querySnapshot = await getDocs(q);
        const conversations = [];
        
        for (const doc of querySnapshot.docs) {
          const conversationData = doc.data();
          // Get the other participant's ID (not the current user)
          const otherParticipantId = conversationData.participants.find(
            id => id !== user.id.toString()
          );
          
          // Fetch the other participant's user data
          const userResponse = await fetch(`${apiUrl}/users/${otherParticipantId}`);
          const userData = await userResponse.json();

          conversations.push({
            id: doc.id,
            lastMessage: conversationData.lastMessage,
            timestamp: conversationData.lastMessageTimestamp,
            otherUser: userData.user
          });
        }

        // Sort conversations by timestamp, newest first
        conversations.sort((a, b) => b.timestamp - a.timestamp);
        setExistingConversations(conversations);
      } catch (error) {
        console.error("Error fetching existing conversations:", error);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchExistingConversations();
  }, [fetchExistingConversations]);

  const handleLanguageClick = (language) => {
    navigate(`/chat/${language.toLowerCase()}`);
  };

  const handleDirectMessageClick = async (otherUser) => {
    try {
      const conversationId = await createConversation(user.id, otherUser.id);
      navigate(`/direct/${conversationId}`);
    } catch (error) {
      console.error("Error initiating direct message:", error);
    }
  };

  const toggleSearchModal = () => {
    setIsSearchModalOpen(!isSearchModalOpen);
  };

  const handleChatRoomsTour = useCallback(() => {
    setIsTourActive(true);

    const overlay = document.createElement('div');
    overlay.className = 'tour-overlay';
    document.body.appendChild(overlay);

    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: { enabled: true },
        scrollTo: { behavior: 'smooth', block: 'center' },
        classes: 'custom-tour-class',
      },
      useModalOverlay: true,
    });

    tour.addStep({
      title: t('chat.tour.welcome.title'),
      text: t('chat.tour.welcome.text'),
      attachTo: {
        element: '.chat-rooms-container',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next
        }
      ]
    });

    tour.addStep({
      title: t('chat.tour.directMessages.title'),
      text: t('chat.tour.directMessages.text'),
      attachTo: {
        element: '.dm-section-title',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next
        }
      ]
    });

    tour.addStep({
      title: t('chat.tour.discover.title'),
      text: t('chat.tour.discover.text'),
      buttons: [
        {
          text: t('dashboard.tour.buttons.back'),
          action: tour.back,
        },
        {
          text: t('dashboard.tour.buttons.next'),
          action: () => {
            tour.complete();
            sessionStorage.setItem('currentTourStep', 'discover_start');
            sessionStorage.setItem('inTour', 'true');
            navigate('/discover');
          },
        },
      ],
    });

    tour.on('complete', () => {
      setIsTourActive(false);
      document.body.removeChild(overlay);
    });

    tour.on('cancel', () => {
      setIsTourActive(false);
      sessionStorage.removeItem('inTour');
      sessionStorage.removeItem('currentTourStep');
      document.body.removeChild(overlay);
    });

    tour.start();
  }, [navigate, t]);

  useEffect(() => {
    const inTour = sessionStorage.getItem('inTour');
    const currentTourStep = sessionStorage.getItem('currentTourStep');

    if (inTour === 'true' && currentTourStep === 'chatrooms_start') {
      setTimeout(() => {
        handleChatRoomsTour();
      }, 100);
    }
  }, [handleChatRoomsTour]);

  return (
    <div className="chat-rooms-page fade-in">
      <h1 className="chat-room-h1 fade-in">{t('chat.rooms.title')}</h1>
      <p className="chat-rooms-note fade-in">
        {t('chat.rooms.description')}
      </p>

      {/* Chat Rooms Section */}
      <div className="chat-rooms-container fade-in">
        {userLanguages.map((language) => (
          <div
            key={language}
            className={`chat-room-card fade-in ${isTourActive ? 'tour-active' : ''}`}
            onClick={() => handleLanguageClick(language)}
          >
            <img
              src={languageImages[language]}
              alt={getTranslatedLanguage(language, t)}
              className="chat-room-image fade-in"
            />
            <div className="chat-room-name fade-in">
              {getTranslatedLanguage(language, t)}
            </div>
            <div className="chat-room-status fade-in">
              {isLoadingCounts ? (
                <span className="loading-members">{t('chat.rooms.loading')}</span>
              ) : (
                t('chat.rooms.members', { count: userCounts[language] || 0 })
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Direct Messages Section */}
      <div className="direct-messages-container fade-in">
        <h2 className="dm-section-title">{t('chat.rooms.directMessages.title')}</h2>
        <button className="add-dm-button" onClick={toggleSearchModal}>
          +
        </button>
        
        {/* Existing conversations list */}
        <div className="existing-conversations-list">
          {existingConversations.map((conversation) => (
            <Link 
              to={`/direct/${conversation.id}`} 
              key={conversation.id}
              className="existing-conversation-item"
            >
              <div className="conversation-user-info">
                <img 
                  src={conversation.otherUser.profile_picture || '/images/default-avatar.png'} 
                  alt={conversation.otherUser.username}
                  className="conversation-user-avatar"
                />
                <div className="conversation-details">
                  <span className="conversation-username">
                    {conversation.otherUser.username}
                  </span>
                  <span className="conversation-last-message">
                    {conversation.lastMessage}
                  </span>
                </div>
                <span className="conversation-time">
                  {new Date(conversation.timestamp).toLocaleDateString()}
                </span>
              </div>
            </Link>
          ))}
        </div>
        
        <DirectMessagesList
          userId={user?.id.toString()}
          onSelectDM={handleDirectMessageClick}
        />
      </div>

      {/* Search Modal */}
      <SearchModal
        isOpen={isSearchModalOpen}
        onClose={toggleSearchModal}
        onUserSelect={handleDirectMessageClick}
        searchType="public"
        userId={user?.id}
      />
    </div>
  );
};

export default ChatRooms;
