import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const GoogleCallback: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleGoogleSignIn = async (email: string, token: string) => {
      try {
        const response = await fetch(`${apiUrl}/signin_with_google`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, token }),
          credentials: 'include',
        });
        const data = await response.json();
        if (data.success) {
          login(data.user);
          navigate('/dashboard');
        } else {
          console.error('Google Sign-In failed:', data.message);
          navigate('/signin', { state: { error: data.message } });
        }
      } catch (error) {
        console.error('Error during Google Sign-In:', error);
        navigate('/signin', { state: { error: 'An error occurred during Google Sign-In' } });
      }
    };

    const urlParams = new URLSearchParams(location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');
    const error = urlParams.get('error');

    if (error) {
      console.error('Google Sign-In error:', error);
      navigate('/signin', { state: { error } });
    } else if (email && token) {
      handleGoogleSignIn(email, token);
    } else {
      navigate('/signin');
    }
  }, [location, login, navigate]);

  return <div>Processing Google Sign-In...</div>;
};

export default GoogleCallback;
