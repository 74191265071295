import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { AuthContext } from '../../contexts/AuthContext';
import './ThreadDetail.css';

const formatTimestamp = (timestamp) => {
  try {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 1) {
      return 'just now';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    } else if (diffInDays < 7) {
      return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    } else {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return 'recently';
  }
};

const languageImages = {
  Spanish: {
    flag: '/images/mexico_flag.png',
    cover: '/images/spain_cover.jpg',
  },
  Japanese: {
    flag: '/images/icon_flag-ja.svg',
    cover: '/images/japanese-cover.webp',
  },
  Portuguese: {
    flag: '/images/icon_flag-pt.svg',
    cover: '/images/portugese-cover.jpg',
  },
  French: {
    flag: '/images/icon_flag-fr.svg',
    cover: '/images/french-cover.jpg',
  },
  German: {
    flag: '/images/icon_flag-de.svg',
    cover: '/images/german-cover.jpg',
  },
  Italian: {
    flag: '/images/icon_flag-it.svg',
    cover: '/images/italian-cover.jpg',
  },
  Mandarin: {
    flag: '/images/icon_flag-zh.svg',
    cover: '/images/mandarin-cover.jpg',
  },
  Arabic: {
    flag: '/images/icon_flag-ar.svg',
    cover: '/images/arabic-cover.jpg',
  },
  Cantonese: {
    flag: '/images/icon_flag-hk.png',
    cover: '/images/cantonese-cover.jpg',
  },
  Korean: {
    flag: '/images/icon_flag-ko.svg',
    cover: '/images/korean-cover.jpg',
  },
  Russian: {
    flag: '/images/icon_flag-ru.svg',
    cover: '/images/russian-cover.jpg',
  },
  Tagalog: {
    flag: '/images/icon_flag_pp.png',
    cover: '/images/tagalog-cover.jpg',
  },
  Greek: {
    flag: '/images/icon_flag_gr.png',
    cover: '/images/greek-cover.jpg',
  },
  Hindi: {
    flag: '/images/icon_flag_in.png',
    cover: '/images/hindi-cover.jpg',
  },
};

const countryToLanguage = {
  '🇮🇹': 'Italian',
  '🇯🇵': 'Japanese',
  '🇪🇸': 'Spanish',
  '🇵🇹': 'Portuguese',
  '🇫🇷': 'French',
  '🇩🇪': 'German',
  '🇨🇳': 'Mandarin',
  '🇸🇦': 'Arabic',
  '🇭🇰': 'Cantonese',
  '🇰🇷': 'Korean',
  '🇷🇺': 'Russian',
  '🇵🇭': 'Tagalog',
  '🇬🇷': 'Greek',
  '🇮🇳': 'Hindi'
};

const ThreadDetail = () => {
  const { threadId } = useParams();
  const { user } = useContext(AuthContext);
  const [thread, setThread] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchThreadDetail = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/travel/threads/${threadId}?userId=${user.id}`
      );
      const data = await response.json();
      setThread(data.thread);
    } catch (error) {
      console.error('Error fetching thread:', error);
    }
  };

  useEffect(() => {
    fetchThreadDetail();
  }, [threadId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim() || isSubmitting) return;

    setIsSubmitting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/travel/threads/${threadId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          content: newComment
        }),
      });

      if (response.ok) {
        const { comment } = await response.json();
        setThread(prev => ({
          ...prev,
          comments: [...(prev.comments || []), comment]
        }));
        setNewComment('');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLikeThread = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/travel/threads/${threadId}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
        }),
      });
      
      if (response.ok) {
        const { likes, isLiked } = await response.json();
        setThread(prev => ({
          ...prev,
          likes,
          isLiked
        }));
      }
    } catch (error) {
      console.error('Error liking thread:', error);
    }
  };

  if (!thread) return <div className="loading">Loading...</div>;

  return (
    <div className="thread-detail-page">
      <div className="thread-detail-container">
        <div className="thread-banner" 
          style={{ 
            backgroundImage: `linear-gradient(rgba(15, 14, 23, 0.5), rgba(15, 14, 23, 0.8)), url(${
              thread.countryEmoji && countryToLanguage[thread.countryEmoji] 
                ? languageImages[countryToLanguage[thread.countryEmoji]].cover 
                : '/images/default-cover.jpeg'
            })` 
          }}
        />
        
        <div className="thread-detail-header">
          <div className="thread-location">
            <span className="country-flag">{thread.countryEmoji}</span>
            <span className={`category-tag ${thread.category}`}>
              {thread.category}
            </span>
          </div>
          <h1>{thread.title}</h1>
          <div className="thread-metadata">
            <div className="thread-author">
              <img src={thread.userProfilePic} alt={thread.userName} />
              <span>{thread.userName}</span>
              <span className="timestamp">• {formatTimestamp(thread.createdAt)}</span>
            </div>
          </div>
        </div>

        <div className="thread-content">
          {thread.content}
        </div>

        <div className="thread-actions">
          <button 
            className={`action-button ${thread.isLiked ? 'liked' : ''}`} 
            onClick={handleLikeThread}
          >
            ❤️ {thread.likes || 0}
          </button>
          <button className="action-button">
            <FontAwesomeIcon icon={faMessage} />
            <span>{thread.comments?.length || 0}</span>
          </button>
        </div>

        <div className="comments-section">
          <h3>Comments ({thread.comments?.length || 0})</h3>
          
          {thread.comments?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((comment) => (
            <div key={comment.id} className="comment">
              <div className="comment-header">
                <div className="comment-user">
                  <img src={comment.userProfilePic} alt={comment.userName} />
                  <span className="username">{comment.userName}</span>
                </div>
                <span className="timestamp">{formatTimestamp(comment.createdAt)}</span>
              </div>
              <p>{comment.content}</p>
            </div>
          ))}
          
          <form className="comment-form" onSubmit={handleAddComment}>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="What are your thoughts?"
              rows="4"
            />
            <div className="comment-form-actions">
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Posting...' : 'Post Comment'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ThreadDetail; 