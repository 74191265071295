import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'tippy.js/dist/tippy.css'; // Import Tippy.js CSS
import './PublicProfile.css'; // Import the CSS for styling
import { AuthContext } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCog, faUserMinus, faUserPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import createConversation from "../../utils/createConversation"; // Make sure this import is at the top of your file
import { useTranslation } from 'react-i18next';

const languageImages = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',
};

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function PublicProfile() {
  const { userId } = useParams();
  const navigate = useNavigate(); 
  const [profile, setProfile] = useState(null);
  const { user } = useContext(AuthContext);
  const [friendRequestStatus, setFriendRequestStatus] = useState('');
  const [badges, setBadges] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Fetch profile data
    fetch(`${apiUrl}/public_profile/${userId}?current_user_id=${user.id}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setProfile(data.profile);
          if (data.profile.is_friend) {
            setFriendRequestStatus(t('profile.friendRequest.friendsSince', { 
              date: data.profile.friends_since 
            }));
          } else if (data.profile.has_sent_request) {
            setFriendRequestStatus(t('profile.friendRequest.sent'));
          }
        } else {
          console.error('Failed to fetch profile:', data.message);
        }
      })
      .catch(error => console.error('Error fetching profile:', error));

    // Fetch badges data
    fetch(`${apiUrl}/get_badges/${userId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setBadges(data.badges);
        } else {
          console.error('Failed to fetch badges:', data.message);
        }
      })
      .catch(error => console.error('Error fetching badges:', error));

  }, [userId, user.id, t]);

  const getProfilePictureSrc = (profilePicture) => {
    if (profilePicture.startsWith('http')) {
      return profilePicture;
    }
    return `${apiUrl}/${profilePicture}`;
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const sendFriendRequest = () => {
    fetch(`${apiUrl}/send_friend_request`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        from_user_id: user.id,
        to_user_id: userId,
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setFriendRequestStatus('Friend request sent successfully');
          setProfile(prevProfile => ({
            ...prevProfile,
            has_sent_request: true
          }));
        } else {
          setFriendRequestStatus(data.message);
        }
      })
      .catch(error => {
        setFriendRequestStatus('Failed to send friend request');
        console.error('Error sending friend request:', error);
      });
  };

  const handleChatClick = async () => {
    try {
      const conversationId = await createConversation(user.id.toString(), userId);
      navigate(`/direct/${conversationId}`);
    } catch (error) {
      console.error("Error initiating direct message:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleSettingsClick = () => {
    if (profile.is_friend) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveFriend = () => {
    fetch(`${apiUrl}/remove_friend`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: user.id,
        friend_id: parseInt(userId, 10)
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Update the profile state to reflect the removed friendship
          setProfile(prevProfile => ({
            ...prevProfile,
            is_friend: false
          }));
          setFriendRequestStatus('');
          setIsModalOpen(false);
          // You might want to show a success message to the user
        } else {
          console.error('Failed to remove friend:', data.message);
          // You might want to show an error message to the user
          alert('Failed to remove friend. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error removing friend:', error);
        // You might want to show an error message to the user
        alert('An error occurred while removing friend. Please try again.');
      });
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  const isOwnProfile = user.id === parseInt(userId, 10);

  return (
    <div className="public-profile-container">
      <div className="profile-details">
        <div className="profile-img">
          <img src={getProfilePictureSrc(profile.profile_picture)} alt="Profile" />
        </div>
        <div className="profile-info">
          <h1>
            {profile.display_name}
            {profile.is_verified && (
              <img 
                src="/images/verified-badge.webp" 
                alt={t('profile.verified')} 
                className="verified-badge" 
                title={t('profile.verifiedAccount')}
              />
            )}
            {profile.is_pro && (
              <span className="pro-badge" title={t('profile.proUser')}>PRO</span>
            )}
          </h1>
          {!isOwnProfile && (
            <div className="profile-actions">
              {profile.is_friend ? (
                <>
                  <button onClick={handleChatClick} className="action-button">
                    <FontAwesomeIcon icon={faComments} /> {t('profile.actions.chat')}
                  </button>
                  <button onClick={handleSettingsClick} className="settings-button">
                    <FontAwesomeIcon icon={faCog} />
                  </button>
                  <button onClick={handleBackClick} className="profile-back-go-back">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                </>
              ) : profile.has_sent_request || friendRequestStatus === t('profile.friendRequest.sent') ? (
                <>
                  <span className="friend-status-text">{t('profile.friendRequest.sent')}</span>
                  <button onClick={handleChatClick} className="action-button">
                    <FontAwesomeIcon icon={faComments} /> {t('profile.actions.chat')}
                  </button>
                </>
              ) : (
                <>
                  <button onClick={sendFriendRequest} className="acc-button-pp">
                    <FontAwesomeIcon icon={faUserPlus} /> {t('profile.actions.sendFriendRequest')}
                  </button>
                  <button onClick={handleChatClick} className="action-button">
                    <FontAwesomeIcon icon={faComments} /> {t('profile.actions.chat')}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      
      <div className="PP-Languages-Header"> 
        <h2>{t('profile.languages.title')}</h2>
      </div>
      {profile.languages.length > 0 ? (
        <div className="languages-container-public-prof">
          {profile.languages.map((lang, index) => (
            <div key={index} className="language-card-pp fade-in">
              <img 
                src={languageImages[lang.language] || '/images/default_flag.png'} 
                alt={t('profile.languages.flagAlt', { language: t(`languages.${lang.language.toLowerCase()}`) })} 
                className="language-image fade-in"
                title={t(`languages.${lang.language.toLowerCase()}`)}
              />
              <div className="language-name fade-in">
                {t(`languages.${lang.language.toLowerCase()}`)}
                {lang.level && (
                  <span className="language-level fade-in">
                    {lang.level}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-content-container">
          <p className="no-languages-text">{t('profile.languages.noLanguages')}</p>
        </div>
      )}
      
      <div className="public-profile-badges-container">
        <h2>{t('profile.badges.title')}</h2>
        {badges.length > 0 ? (
          <div className="public-profile-badges-grid">
            {badges.map(badge => (
              <div 
                key={badge.badge_name} 
                className={`public-profile-badge-card ${badge.level ? badge.level : 'locked'}`}>
                <img src={badge.badge_image} alt={badge.badge_name} className="public-profile-badge-image" />
              </div>
            ))}
          </div>
        ) : (
          <div className="no-content-container">
            <p className="no-badges-text">{t('profile.badges.noBadges')}</p>
          </div>
        )}
      </div>
      
      {isModalOpen && profile.is_friend && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>{t('profile.modal.title')}</h3>
            <button onClick={handleRemoveFriend} className="modal-button">
              <FontAwesomeIcon icon={faUserMinus} /> {t('profile.actions.removeFriend')}
            </button>
            <button onClick={handleCloseModal} className="modal-button close">
              {t('common.close')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicProfile;
