import React from 'react';
import './DirectMessagesList.css';

function DirectMessagesList() {
  return (
    <div className="direct-messages-list">
      <div className="dm-instructions">
        <p>Click the + button above to find users or start a new conversation</p>
      </div>
    </div>
  );
}

export default DirectMessagesList;