import React, { useEffect, useContext, useState, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faUser, faEarthAmericas, faMessage, faFire } from '@fortawesome/free-solid-svg-icons';
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons'; // Regular outlined bell
import Home from './Home';
import SelectLanguages from './SelectLanguages';
import Club from '../Club/Club';
import Account from '../Account/Account';
import SignIn from '../Auth/SignIn';
import ForgotMyPassword from '../Auth/ForgotMyPassword';
import ResetPassword from '../Auth/ResetPassword';
import Register from '../Auth/Register';
import RegisterWithGoogle from '../Auth/RegisterWithGoogle';
import Dashboard from '../Dashboard/Dashboard';
import LanguagePage from '../LanguagePage/LanguagePage';
import Connect from '../Connect/Connect';
import Chat from '../Chat/Chat';
import DirectMessages from '../Chat/DirectMessages';
import ChatRooms from '../Chat/ChatRooms';
import Discover from '../PublicProfiles/Discover';
import PublicProfile from '../PublicProfiles/PublicProfile';
import VocabularyRace from '../Games/VocabularyRace';
import StoryChain from '../Games/StoryChain';
import SpotifyPlayer from '../Spotify/SpotifyPlayer';
import NavbarSpotifyPlayer from '../../components/NavbarSpotifyPlayer';
import GoogleCallback from '../Auth/GoogleCallback';
import ScrollToTop from '../../components/ScrollToTop'; // Add this import
import MobileNavbar from '../../components/MobileNavbar/MobileNavbar';
import Travel from '../Travel/Travel';
import ThreadDetail from '../ThreadDetail/ThreadDetail';

import { AuthContext } from '../../contexts/AuthContext';
import { PlaybackProvider } from '../Spotify/PlaybackContext'; // Ensure PlaybackProvider is imported
import '../../styles/GlobalStyles.css';

import '../../config/i18n';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Utility functions
const filterRecentNotifications = (notifications) => {
  const now = new Date();
  return notifications.filter(notification => {
    const notificationTime = new Date(notification.created_at);
    const differenceInDays = (now - notificationTime) / (1000 * 60 * 60 * 24);
    return differenceInDays <= 5;
  });
};

function App() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [currentStreak, setCurrentStreak] = useState(0);
  const [notifications, setNotifications] = useState([]); // Notifications state
  const [showDropdown, setShowDropdown] = useState(false); // Toggle dropdown visibility

  const noNavbarRoutes = useMemo(() => ['/register', '/signin', '/registerwithgoogle', '/selectlanguages', '/forgotmypassword'], []);

  const getProfilePictureSrc = (profilePicture) => {
    return profilePicture.startsWith('http') ? profilePicture : `${apiUrl}/${profilePicture}`;
  };

  // Fetch notifications from backend
  useEffect(() => {
    const fetchNotifications = async () => {
      if (user) {
        try {
          const response = await fetch(`${apiUrl}/notifications?user_id=${user.id}`);
          const data = await response.json();
          const recentNotifications = filterRecentNotifications(data.notifications || []);
          setNotifications(recentNotifications);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      }
    };
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  // Mark notifications as read
  const markNotificationsAsRead = async () => {
    try {
      const response = await fetch(`${apiUrl}/notifications/mark_as_read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: user.id }), // Send user ID in the request
      });

      const data = await response.json();
      if (data.success) {
        setNotifications((prev) =>
          prev.map((notification) => ({ ...notification, read: true })) // Mark all as read
        );
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  // Handle opening the dropdown (marks notifications as read)
  const handleBellClick = () => {
    setShowDropdown((prev) => !prev);
    if (!showDropdown && unreadCount > 0) {
      markNotificationsAsRead();
    }
  };

  // Get unread notification count
  const unreadCount = notifications.filter((notification) => !notification.read).length;

  // Handle scroll to add/remove 'scrolled' class
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('navbar');
      if (navbar) {
        if (window.scrollY > 0) {
          navbar.classList.add('scrolled');
        } else {
          navbar.classList.remove('scrolled');
        }
      }
    };

    const handleLocationChange = () => {
      const path = location.pathname.toLowerCase();
      const isNoNavbarRoute = noNavbarRoutes.includes(path) || path.startsWith('/resetpassword');
      if (!isNoNavbarRoute) {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };

    handleLocationChange();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, noNavbarRoutes]);

  // Update streak
  useEffect(() => {
    const updateStreak = async () => {
      if (!user) return;

      try {
        const lastStreakKey = `lastStreakCheck_${user.id}`;
        const lastCheck = localStorage.getItem(lastStreakKey);
        const today = new Date().toISOString().split('T')[0];

        // Only proceed if no check today
        if (!lastCheck || new Date(lastCheck).toISOString().split('T')[0] !== today) {
          const updateResponse = await fetch(`${apiUrl}/update_streak?user_id=${user.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              user_id: user.id,
              last_check: lastCheck
            })
          });
          
          const data = await updateResponse.json();
          if (data.success) {
            setCurrentStreak(data.current_streak);
            localStorage.setItem(lastStreakKey, today);
          }
        } else {
          // Just get current streak if already checked today
          const response = await fetch(`${apiUrl}/get_current_streak?user_id=${user.id}`);
          const data = await response.json();
          setCurrentStreak(data.current_streak || 0);
        }
      } catch (error) {
        console.error('Error managing streak:', error);
      }
    };

    updateStreak();
  }, [user]);

  const basePath = location.pathname.toLowerCase();

  const handleNotificationClick = (notification) => {
    if (notification.type === "chat_reply") {
      const content = notification.content;
      const languageMatch = content.match(/in the (\w+) chat/);
      if (languageMatch && languageMatch[1]) {
        const language = languageMatch[1];
        navigate(`/chat/${language.toLowerCase()}`);
      }
    } else if (notification.content.includes("sent you a friend request")) {
      navigate('/account');
    }

    // Close the dropdown after clicking
    setShowDropdown(false);
  };

  // Move getRelativeTime inside the component
  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const notificationTime = new Date(timestamp);
    const differenceInSeconds = (now - notificationTime) / 1000;

    const minutes = Math.floor(differenceInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) return t('notifications.timeAgo.seconds', { count: Math.floor(differenceInSeconds) });
    if (minutes < 60) return t('notifications.timeAgo.minutes', { count: minutes });
    if (hours < 24) return t('notifications.timeAgo.hours', { count: hours });
    return t('notifications.timeAgo.days', { count: days });
  };

  // Debugging

  return (
    <PlaybackProvider>
      <div className="App">
        <ScrollToTop />
        {!noNavbarRoutes.includes(basePath) && (
          <div className="navbar" id="navbar">
            <div className="navbar-content">
              {/* Left Section: Branding */}
              <div className="navbar-left">
                <Link to="/home" className="navbar-brand">
                  <img src="/images/speakeasylogo.png" alt="Speak Easy Logo" className="navbar-logo" />
                  <img src="/images/IMG_1668.png" alt="New logo" className="navbar-new-img" />
                </Link>
              </div>

              {/* Center Section: Navigation Links */}
              <div className="nav-links desktop-only">
                {user && (
                  <>
                    <Link to="/dashboard" className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                      <FontAwesomeIcon icon={faChartBar} className="icon" />
                      <span className="text">{t('nav.dashboard')}</span>
                    </Link>
                    <Link to="/chatrooms" className={`nav-link ${location.pathname === '/chatrooms' ? 'active' : ''}`}>
                      <FontAwesomeIcon icon={faMessage} className="icon" />
                      <span className="text">{t('nav.chat')}</span>
                    </Link>
                    <Link to="/discover" className={`nav-link ${location.pathname === '/discover' ? 'active' : ''}`}>
                      <FontAwesomeIcon icon={faEarthAmericas} className="icon" />
                      <span className="text">{t('nav.discover')}</span>
                    </Link>
                    <Link to="/account" className={`nav-link ${location.pathname === '/account' ? 'active' : ''}`}>
                      <FontAwesomeIcon icon={faUser} className="icon" />
                      <span className="text">{t('nav.account')}</span>
                    </Link>
                  </>
                )}
              </div>

              {/* Right Section: Spotify Player, Notifications, Streaks, Profile */}
              <div className="navbar-right">
                {user && (
                  <>
                    {/* Spotify Player */}
                      <NavbarSpotifyPlayer />

                    {/* Notification Bell */}
                    <div className="notification-bell" onClick={handleBellClick}>
                      <FontAwesomeIcon icon={faBellRegular} className="bell-icon" />
                      {unreadCount > 0 && (
                        <span className="notification-count">{unreadCount}</span>
                      )}

                      {showDropdown && (
                        <div className="notification-dropdown">
                          <h3>{t('notifications.title')}</h3>
                          <ul className="notification-list">
                            {notifications
                              .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                              .map((notification, index) => (
                                <li
                                  key={index}
                                  className={notification.read ? '' : 'unread'}
                                  onClick={() => handleNotificationClick(notification)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <span className="notification-message">
                                    {notification.content}
                                  </span>
                                  <span className="time-ago">
                                    {getRelativeTime(notification.created_at)}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* Streaks */}
                    <div className="streak-container">
                      <FontAwesomeIcon icon={faFire} className="streak-icon" />
                      <span>{currentStreak}</span>
                    </div>

                    {/* Profile Picture */}
                    <div className="profile-container">
                      <div className="profile-icon" onClick={() => navigate('/account')}>
                        <img src={getProfilePictureSrc(user.profile_picture)} alt={user.display_name} />
                      </div>
                    </div>
                  </>
                )}
                {!user && (
                  <Link to="/signin" className="sign-in-link">Sign In</Link>
                )}
              </div>
            </div>
          </div>
        )}
        <Routes>
          <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Navigate to="/home" />} />
          <Route path="/home" element={!user ? <Home /> : <Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/home" />} />
          <Route path="/club" element={<Club />} />
          <Route path="/account" element={user ? <Account /> : <Navigate to="/signin" />} />
          <Route path="/signin" element={!user ? <SignIn /> : <Navigate to="/dashboard" />} />
          <Route path="/google-callback" element={<GoogleCallback />} /> {/* Add this new route */}
          <Route path="/register" element={!user ? <Register /> : <Navigate to="/dashboard" />} />
          <Route path="/registerwithgoogle" element={!user ? <RegisterWithGoogle /> : <Navigate to="/dashboard" />} />
          <Route path="/language/:language" element={<LanguagePage />} />
          <Route path="/connect/:language" element={<Connect />} />
          <Route path="/chatrooms" element={<ChatRooms />} />
          <Route path="/chat/:language" element={user ? <Chat /> : <Navigate to="/signin" />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/public_profile/:userId" element={<PublicProfile />} />
          <Route path="/selectlanguages" element={<SelectLanguages />} />
          <Route path="/forgotmypassword" element={<ForgotMyPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/direct/:chatRoomId" element={user ? <DirectMessages /> : <Navigate to="/signin" />} />
          <Route path="/vocabularyrace/:language" element={<VocabularyRace />} />
          <Route path="/storychain/:language" element={<StoryChain />} />
          <Route path="/spotify-player" element={<SpotifyPlayer />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/travel/threads/:threadId" element={<ThreadDetail />} /> 
        </Routes>
        {!noNavbarRoutes.includes(basePath) && <MobileNavbar user={user} />}
      </div>
    </PlaybackProvider>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
