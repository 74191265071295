import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './TravelSection.css';

const TravelSection = () => {
  const { t } = useTranslation();

  return (
    <div className="travel-feature-section">
      <Link to="/travel" className="travel-feature-link">
        <div className="travel-feature-content">
          <div className="travel-feature-title-wrapper">
            <span className="travel-feature-beta">BETA</span>
            <h2 className="travel-feature-title">{t('discover.travel.title')}</h2>
          </div>
          <img 
            src="/images/travel-icon.webp" 
            alt={t('discover.travel.iconAlt')} 
            className="travel-feature-icon" 
          />
        </div>
        <p className="travel-feature-description">
          {t('discover.travel.description')}
        </p>
      </Link>
    </div>
  );
};

export default TravelSection; 