import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './SignIn.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_BASE_URL}/reset_password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, password }),
    })
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
        if (data.message === 'Password has been reset successfully') {
          setIsSuccess(true);
        }
      })
      .catch(error => {
        console.error('Error resetting password:', error);
        setMessage('An error occurred. Please try again later.');
      });
  };

  return (
    <div className="sign-in-page">
      <div
        className="sign-in-left"
        style={{
          backgroundImage: `url('/images/register-left.jpeg')`,
        }}
      >
        <div className="sign-in-left-content">
          {/* Any additional content can go here */}
        </div>
      </div>
      <div className="sign-in-right">
        <h2 className="sign-in-title-reset-pass">Reset Your Password</h2>
        {message && <p className="sign-in-message">{message}</p>}
        {!isSuccess ? (
          <form onSubmit={handleSubmit} className="sign-in-form">
            <div className="sign-in-form-group">
              <label htmlFor="password" className="sign-in-label">New Password:</label>
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="sign-in-input"
                />
                <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </div>
            <button type="submit" className="register-get-started-button">Reset Password</button>
          </form>
        ) : (
          <button 
            className="register-get-started-button" 
            onClick={() => navigate('/signin')}
          >
            Go to Sign In
          </button>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
