import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import speechService from '../../services/speechService';
import './WordPackModal.css';

const WordPackModal = ({ isOpen, onClose, phrases, language, theme, context }) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isRevealed, setIsRevealed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const COOLDOWN_DURATION = 3000; // 3 seconds cooldown

  useEffect(() => {
    if (isOpen) {
      setCurrentPhraseIndex(0);
      setIsRevealed(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleNext = () => {
    if (currentPhraseIndex < phrases.length - 1) {
      setCurrentPhraseIndex(prev => prev + 1);
      setIsRevealed(false);
    } else {
      handleClose();
    }
  };

  const handleReveal = () => {
    setIsRevealed(true);
  };

  const handleSpeak = async (text) => {
    if (isPlaying || cooldown) return;
    
    setIsPlaying(true);
    setCooldown(true);
    
    try {
      await speechService.speak(text, language);
    } catch (error) {
      console.error('Speech synthesis error:', error);
    } finally {
      setIsPlaying(false);
      // Keep the mute icon during cooldown
      setTimeout(() => {
        setCooldown(false);
      }, COOLDOWN_DURATION);
    }
  };

  if (!isOpen) return null;

  const currentPhrase = phrases[currentPhraseIndex];

  return (
    <div className="wordpack-modal-overlay">
      <div className="wordpack-modal">
        <div className="pack-content">
          {context && (
            <div className="pack-context">
              <h3>Context: {context}</h3>
              <p className="context-description">
                These phrases are commonly used when {context.toLowerCase()}
              </p>
            </div>
          )}

          <div className="phrase-counter">
            {currentPhraseIndex + 1} / {phrases.length}
          </div>

          <div className="phrase-card">
            <div className="phrase-text">
              {currentPhrase.phrase}
              <button 
                className={`audio-button ${(isPlaying || cooldown) ? 'playing' : ''}`}
                onClick={() => handleSpeak(currentPhrase.phrase)}
                disabled={isPlaying || cooldown}
              >
                <FontAwesomeIcon icon={(isPlaying || cooldown) ? faVolumeMute : faVolumeUp} />
              </button>
            </div>

            <div className={`phrase-translation ${isRevealed ? 'revealed' : ''}`}>
              {isRevealed ? (
                currentPhrase.translation
              ) : (
                <button className="reveal-button" onClick={handleReveal}>
                  Reveal Translation
                </button>
              )}
            </div>
          </div>

          <div className="navigation-buttons">
            {currentPhraseIndex < phrases.length - 1 ? (
              <button className="next-button" onClick={handleNext}>
                Next Phrase
              </button>
            ) : (
              <button className="finish-button" onClick={handleClose}>
                Finish
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordPackModal; 