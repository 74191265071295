// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA91x358fY8hdtZjYMRHHJ6lXQ8uvH5Bm4",
  authDomain: "speakeasy-c3d76.firebaseapp.com",
  projectId: "speakeasy-c3d76",
  storageBucket: "speakeasy-c3d76.appspot.com",
  messagingSenderId: "822661266399",
  appId: "1:822661266399:web:62d566308639709d03a26a",
  measurementId: "G-WWXBLRWR6T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };
