import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import './DailyLimitModal.css';

function DailyLimitModal({ isOpen, onClose, nextAvailableTime }) {
  if (!isOpen) return null;

  return (
    <div className="wordpack-intro-overlay">
      <div className="wordpack-intro-modal">
        <button className="intro-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="intro-content">
          <div className="intro-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>

          <h2 className="intro-title">Daily Limit Reached</h2>
          
          <div className="intro-body">
            <p>You've already opened your word pack for today!</p>
            <div className="next-available-container">
              <p>Next pack available at:</p>
              <div className="time-display">{nextAvailableTime}</div>
            </div>
          </div>

          <button className="intro-next-button" onClick={onClose}>
            Got it
          </button>
        </div>
      </div>
    </div>
  );
}

export default DailyLimitModal; 