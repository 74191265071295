import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateThreadModal from '../../components/CreateThreadModal/CreateThreadModal';
import Select from 'react-select';
import './Travel.css';

// Move emojiToCountryCode outside the component
const emojiToCountryCode = {
  '🇮🇹': 'it',
  '🇯🇵': 'jp',
  '🇫🇷': 'fr',
  '🇪🇸': 'es',
};

const Travel = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [threads, setThreads] = useState([]);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const navigate = useNavigate();
  const [activeThreadIndex, setActiveThreadIndex] = useState(0);
  const hasLoadedThreads = useRef(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState('all');

  const backgroundVideos = useMemo(() => [
    '../../videos/stock1.mp4',
    '../../videos/stock2.mp4',
    '../../videos/stock3.mp4',
  ], []);

  const threadCategories = {
    question: {
      label: 'Question',
      icon: '❓',
      description: 'Ask the community about specific destinations'
    },
    recommendation: {
      label: 'Recommendation',
      icon: '⭐',
      description: 'Share your favorite spots and tips'
    },
    guide: {
      label: 'Travel Guide',
      icon: '📍',
      description: 'Share detailed itineraries and experiences'
    },
    tip: {
      label: 'Quick Tip',
      icon: '💡',
      description: 'Share short, helpful travel tips'
    }
  };

  const countryOptions = [
    { value: 'all', label: '🌎 All Locations' },
    { value: 'it', label: '🇮🇹 Italy' },
    { value: 'jp', label: '🇯🇵 Japan' },
    { value: 'fr', label: '🇫🇷 France' },
    { value: 'es', label: '🇪🇸 Spain' },
    // Add more countries...
  ];

  useEffect(() => {
    if (!hasLoadedThreads.current) {
      fetchThreads();
      hasLoadedThreads.current = true;
    }
  }, []);

  useEffect(() => {
    if (threads.length > 0) {
      const middleIndex = Math.floor(threads.length / 2);
      setActiveThreadIndex(middleIndex);
    }
  }, [threads]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex(prev => {
        if (prev === backgroundVideos.length - 1) {
          requestAnimationFrame(() => {
            const slider = document.querySelector('.video-slider');
            if (slider) {
              slider.style.transition = 'none';
              slider.style.transform = 'translateX(0)';
              requestAnimationFrame(() => {
                slider.style.transition = 'transform 1.5s ease-in-out';
              });
            }
          });
          return 0;
        }
        return prev + 1;
      });
    }, 8000);

    return () => clearInterval(interval);
  }, [backgroundVideos.length]);

  const fetchThreads = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/travel/threads`);
      const data = await response.json();
      setThreads(data.threads);
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };

  const handleCreateThread = async (formData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/travel/threads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          userId: user.id,
        }),
      });
      
      if (response.ok) {
        setIsCreatingThread(false);
        hasLoadedThreads.current = false;
        fetchThreads();
      }
    } catch (error) {
      console.error('Error creating thread:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    try {
      // Parse the timestamp string directly without timezone conversion
      const date = new Date(timestamp.replace(' ', 'T') + 'Z');
      const now = new Date();

      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.error('Invalid timestamp:', timestamp);
        return 'recently';
      }

      // Calculate time difference
      const diffInMs = now.getTime() - date.getTime(); // Reversed the order for past events
      
      // Convert to appropriate units
      const diffInMinutes = Math.round(diffInMs / (1000 * 60));
      const diffInHours = Math.round(diffInMs / (1000 * 60 * 60));
      const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));

      const rtf = new Intl.RelativeTimeFormat('en', { 
        numeric: 'auto',
        style: 'long'
      });

      if (diffInMinutes < 1) {
        return 'just now';
      } else if (diffInMinutes < 60) {
        return rtf.format(-diffInMinutes, 'minute');
      } else if (diffInHours < 24) {
        return rtf.format(-diffInHours, 'hour');
      } else {
        return rtf.format(-diffInDays, 'day');
      }
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'recently';
    }
  };

  const handleNext = () => {
    if (activeThreadIndex < threads.length - 1) {
      setActiveThreadIndex(prev => prev + 1);
    }
  };

  const handlePrev = () => {
    if (activeThreadIndex > 0) {
      setActiveThreadIndex(prev => prev - 1);
    }
  };

  const handleLike = async (threadId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/travel/threads/${threadId}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
        }),
      });
      
      if (response.ok) {
        const data = await response.json();
        setThreads(prevThreads => 
          prevThreads.map(thread => 
            thread.id === threadId 
              ? { ...thread, likes: data.likes, isLiked: data.isLiked }
              : thread
          )
        );
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const filteredThreads = useMemo(() => {
    if (selectedCountry === 'all') return threads;
    return threads.filter(thread => {
      const threadCountryCode = emojiToCountryCode[thread.countryEmoji];
      return threadCountryCode === selectedCountry;
    });
  }, [threads, selectedCountry]);

  useEffect(() => {
    if (filteredThreads.length > 0) {
      const middleIndex = Math.floor(filteredThreads.length / 2);
      setActiveThreadIndex(middleIndex);
    }
  }, [filteredThreads.length]);

  return (
    <div className="travel-page">
      <div className="travel-hero">
        <div 
          className="video-slider" 
          style={{ 
            transform: `translateX(-${currentVideoIndex * 33.333}%)`
          }}
        >
          {backgroundVideos.map((video, index) => (
            <div key={video} className="video-slide">
              <video
                className="travel-hero-video"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={video} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>

        <div className="travel-hero-content">
          <h1>Explore the World Together</h1>
          <button className="travel-hero-btn">
            Join Travel Discussions
          </button>
        </div>
      </div>

      <div className="travel-content">
        <div className="country-filter">
          <Select
            options={countryOptions}
            defaultValue={countryOptions[0]}
            onChange={(option) => setSelectedCountry(option.value)}
            className="country-select"
            classNamePrefix="country-select"
          />
        </div>

        <section className="discussions-section">
          <div className="section-header">
            <h2>Recent Discussions</h2>
            <button 
              className="create-thread-btn"
              onClick={() => setIsCreatingThread(true)}
            >
              + New Thread
            </button>
          </div>

          <div className="thread-container">
            <div className="thread-grid">
              {filteredThreads.map((thread, index) => (
                <div 
                  key={thread.id} 
                  className={`thread-card ${
                    index === activeThreadIndex 
                      ? 'active'
                      : index < activeThreadIndex 
                        ? `before ${index === activeThreadIndex - 1 ? 'adjacent' : ''}` 
                        : `after ${index === activeThreadIndex + 1 ? 'adjacent' : ''}`
                  }`}
                  onClick={() => navigate(`/travel/threads/${thread.id}`)}
                >
                  <div className="thread-card-header">
                    <span className="country-flag">{thread.countryEmoji}</span>
                    <span className={`category-tag ${thread.category}`}>
                      {t(`travel.categories.${thread.category}`)}
                    </span>
                  </div>
                  <h3 className="thread-title">{thread.title}</h3>
                  <p className="thread-preview">{thread.content.slice(0, 100)}...</p>
                  <div className="thread-footer">
                    <div className="user-info">
                      <img src={thread.userProfilePic} alt={thread.userName} />
                      <span>{thread.userName}</span>
                    </div>
                    <div className="thread-stats">
                      <span className="stat">
                        <FontAwesomeIcon icon={faMessage} />
                        {thread.comments?.length || 0}
                      </span>
                      <span 
                        className={`stat ${thread.isLiked ? 'liked' : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLike(thread.id);
                        }}
                      >
                        ❤️ {thread.likes || 0}
                      </span>
                    </div>
                    <span className="thread-time">{formatTimestamp(thread.createdAt)}</span>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="thread-navigation">
              <button 
                className="nav-button"
                onClick={handlePrev}
                disabled={activeThreadIndex === 0}
              >
                ←
              </button>
              <button 
                className="nav-button"
                onClick={handleNext}
                disabled={activeThreadIndex === filteredThreads.length - 1}
              >
                →
              </button>
            </div>
          </div>
        </section>
      </div>

      {isCreatingThread && (
        <CreateThreadModal
          isOpen={isCreatingThread}
          onClose={() => setIsCreatingThread(false)}
          onSubmit={handleCreateThread}
          threadCategories={threadCategories}
        />
      )}
    </div>
  );
};

export default Travel; 