interface StartCallParams {
  call_id: string;
  user1_id: string;
  user2_id: string;
  language: string;
}

interface EndCallParams {
  call_id: string;
  connection_quality?: string;
}

export const startVideoCall = async (params: StartCallParams) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/video_call/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  
  if (!response.ok) {
    throw new Error('Failed to start video call');
  }
  
  return response.json();
};

export const endVideoCall = async (params: EndCallParams) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/video_call/end`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  
  if (!response.ok) {
    throw new Error('Failed to end video call');
  }
  
  return response.json();
};

