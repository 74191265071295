import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import VideoChat from "./VideoChat";
import { findOrCreateCall } from "./callUtils";
import VideoChatModal from "./VideoChatModal";
import "./Connect.css";
import { useAuth } from "../../contexts/AuthContext";
import { startVideoCall, endVideoCall } from "./api";
import { useWebRTC } from "./useWebRTC";

export default function Connect() {
  const { t } = useTranslation();
  const { language } = useParams<{ language: string }>();
  const [callId, setCallId] = useState<string | null>(null);
  const { user, loading } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [currentCallDbId, setCurrentCallDbId] = useState<number | null>(null);
  const [friendRequestStatus, setFriendRequestStatus] = useState<{
    message: string;
    type: 'success' | 'error' | null;
  }>({ message: '', type: null });

  const handleFriendRequestStatus = useCallback((success: boolean, message: string) => {
    setFriendRequestStatus({
      message,
      type: success ? 'success' : 'error'
    });

    setTimeout(() => {
      setFriendRequestStatus({ message: '', type: null });
    }, 3000);
  }, []);

  // Always call useWebRTC, but pass null values if no user
   // eslint-disable-next-line
  const { setupWebRTC, cleanupWebRTC } = useWebRTC(
    user?.id || '', 
    user?.display_name || '', 
    user?.profile_picture || '',
    user ? {
      onCallConnected: async (remoteUserId: string) => {
        if (!user || !callId || !language) return;
        try {
          const response = await startVideoCall({
            call_id: callId,
            user1_id: user.id,
            user2_id: remoteUserId,
            language: language
          });
          if (response.success) {
            setCurrentCallDbId(response.call_id);
          }
        } catch (error) {
          console.error(t('connect.errors.callStart'), error);
        }
      },
      onCallEnded: async () => {
        if (currentCallDbId && callId) {
          try {
            await endVideoCall({
              call_id: callId,
              connection_quality: 'good'
            });
          } catch (error) {
            console.error(t('connect.errors.callEnd'), error);
          }
        }
      }
    } : undefined
  );

  const handleFindOrCreateCall = useCallback(() => {
    if (user && language) {
      setIsSearching(true);
      findOrCreateCall(language, user, (newCallId: string, otherUserId?: string) => {
        setCallId(newCallId);
        if (otherUserId) {
          startVideoCall({
            call_id: newCallId,
            user1_id: user.id,
            user2_id: otherUserId,
            language: language
          }).then(response => {
            if (response.success) {
              setCurrentCallDbId(response.call_id);
            }
          }).catch(error => {
            console.error("Failed to record call start:", error);
          });
        }
      });
    }
  }, [language, user]);

  const handleLeaveCall = useCallback(async () => {
    if (currentCallDbId && callId) {
      try {
        const response = await endVideoCall({
          call_id: callId,
          connection_quality: 'good'
        });
        console.log(t('connect.logs.callEnded'), response);
      } catch (error) {
        console.error(t('connect.errors.callEnd'), error);
      }
    }
    setCallId(null);
    setCurrentCallDbId(null);
    setIsSearching(false);
    cleanupWebRTC();
  }, [callId, currentCallDbId, cleanupWebRTC, t]);

  const handleCloseModal = () => {
    setShowModal(false);
    sessionStorage.setItem("hasSeenVideoChatModal", "true");
  };

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem("hasSeenVideoChatModal");
    if (!hasSeenModal) {
      setShowModal(true);
    }
  }, []);

  if (loading) {
    return <p>{t('connect.status.loading')}</p>;
  }

  if (!user) {
    return <p>{t('connect.status.loginRequired')}</p>;
  }

  return (
    <div className="connect-container">
      <h1>{t('connect.title')}</h1>
      {friendRequestStatus.message && (
        <div className={`friend-request-status ${friendRequestStatus.type}`}>
          {friendRequestStatus.message}
        </div>
      )}
      <VideoChat
        callId={callId}
        userId={user.id}
        displayName={user.display_name}
        profilePicture={user.profile_picture}
        onFindOrCreateCall={handleFindOrCreateCall}
        onLeaveCall={handleLeaveCall}
        isSearching={isSearching}
        onFriendRequestStatus={handleFriendRequestStatus}
      />
      
      {isSearching && !callId && (
        <p>{t('connect.status.searching')}</p>
      )}

      <VideoChatModal isOpen={showModal} onClose={handleCloseModal} />
    </div>
  );
}
