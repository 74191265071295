import React, { useState, useCallback, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "../../contexts/AuthContext";
import "./SearchModal.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SearchModal = ({ isOpen, onClose, onUserSelect, searchType = "public", userId }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const { user } = useContext(AuthContext);

  // Reset search term and results when modal is opened
  useEffect(() => {
    if (isOpen) {
      setSearchTerm("");
      setSearchResults([]);
    }
  }, [isOpen]);

  const fetchProfiles = useCallback(async (search = '') => {
    setIsSearching(true);
    try {
      let endpoint;
      if (searchType === "public") {
        endpoint = `${apiUrl}/public_profiles?search=${search}`;
      } else if (searchType === "friends") {
        endpoint = `${apiUrl}/friends?user_id=${userId}&search=${search}`;
      } else if (searchType === "friend_requests") {
        endpoint = `${apiUrl}/friend_requests?user_id=${userId}&search=${search}`;
      }
      
      const response = await fetch(endpoint);
      const data = await response.json();
      if (data.success) {
        let results;
        if (searchType === "public") {
          results = data.profiles;
        } else if (searchType === "friends") {
          results = data.friends;
        } else if (searchType === "friend_requests") {
          results = data.friend_requests;
        }
        const filteredResults = results.filter(profile => profile.id !== user.id);
        setSearchResults(filteredResults);
      } else {
        console.error(t('searchModal.errors.fetch_profiles'));
      }
    } catch (error) {
      console.error(t('searchModal.errors.fetch_error'), error);
    } finally {
      setIsSearching(false);
    }
  }, [searchType, userId, user.id, t]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchProfiles(searchTerm.trim());
  };

  if (!isOpen) return null;

  return (
    <div className="search-modal">
      <div className="search-modal-content">
        <button className="close-button-search" onClick={onClose}>
          {t('searchModal.button.close')}
        </button>
        <h2>{t(`searchModal.title.${searchType}`)}</h2>
        <form onSubmit={handleSearchSubmit} className="search-form">
          <input 
            className="search-modal-input"
            type="text"
            placeholder={t(`searchModal.placeholder.${searchType}`)}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button 
            type="submit" 
            className="search-button" 
            disabled={isSearching}
          >
            {isSearching 
              ? t('searchModal.button.searching')
              : t('searchModal.button.search')
            }
          </button>
        </form>
        <div className="search-results">
          {searchResults.map((item) => (
            <div
              key={item.id}
              className="search-result-item"
              onClick={() => onUserSelect(item)}
            >
              <img
                src={
                  item.profile_picture || item.from_user_profile_picture
                    ? (item.profile_picture || item.from_user_profile_picture).startsWith("http")
                      ? item.profile_picture || item.from_user_profile_picture
                      : `${apiUrl}/${item.profile_picture || item.from_user_profile_picture}`
                    : "/images/default_profile.png" // Fallback image
                }
                alt={item.display_name || item.from_user_name}
                className="search-result-image"
              />
              <div className="search-result-name">{item.display_name || item.from_user_name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
