import { useCallback, useRef } from "react";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";

interface CandidateAndUserId {
  candidate: RTCIceCandidate;
  userId: string;
}

interface WebRTCCallbacks {
  onCallConnected?: (remoteUserId: string) => void;
  onCallEnded?: () => void;
}

const iceServers = [{ urls: "stun:stun.l.google.com:19302" }];

export const useWebRTC = (
  userId: string, 
  displayName: string, 
  profilePicture: string,
  callbacks?: WebRTCCallbacks
) => {
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const candidatesBuffer = useRef<CandidateAndUserId[]>([]);
  const candidatesTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const sendCandidatesToFirebase = useCallback(async (callId: string) => {
    if (candidatesBuffer.current.length === 0) return;

    const batch = writeBatch(firestore);
    const callDoc = doc(firestore, "calls", callId);

    candidatesBuffer.current.forEach((candidateData) => {
      const candidateDoc = doc(collection(callDoc, "candidates"));
      const serializedCandidate = {
        candidate: candidateData.candidate.candidate,
        sdpMid: candidateData.candidate.sdpMid,
        sdpMLineIndex: candidateData.candidate.sdpMLineIndex,
        usernameFragment: candidateData.candidate.usernameFragment,
        userId: candidateData.userId,
      };
      batch.set(candidateDoc, serializedCandidate);
    });

    try {
      await batch.commit();
      console.log(`Sent ${candidatesBuffer.current.length} candidates to Firebase`);
      candidatesBuffer.current = []; // Clear the buffer after sending
    } catch (error) {
      console.error("Error sending candidates to Firebase:", error);
    }
  }, []);

  const setupWebRTC = useCallback(
    (
      callId: string,
      localStream: MediaStream,
      setRemoteStream: (stream: MediaStream) => void,
      onRemotePeerDisconnected: () => void,
      setRemoteUserInfo: (data: { name: string; id: string; profilePicture: string }) => void
    ) => {
      const callDoc = doc(firestore, "calls", callId);
      const candidatesCollection = collection(callDoc, "candidates");

      const pc = new RTCPeerConnection({ iceServers });
      peerConnectionRef.current = pc;

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          const candidateWithUserId = {
            candidate: event.candidate,
            userId: userId,
          };
          candidatesBuffer.current.push(candidateWithUserId);

          if (candidatesTimeoutRef.current) {
            clearTimeout(candidatesTimeoutRef.current);
          }

          candidatesTimeoutRef.current = setTimeout(() => {
            sendCandidatesToFirebase(callId);
          }, 1000);
        }
      };

      pc.ontrack = (event) => {
        console.log("Received remote track:", event.track.kind);
        const newRemoteStream = event.streams[0];
        if (!newRemoteStream) return;
        setRemoteStream(newRemoteStream);
      };

      pc.oniceconnectionstatechange = () => {
        if (pc.iceConnectionState === "disconnected") {
          console.log("Remote peer has disconnected");
          callbacks?.onCallEnded?.();
          onRemotePeerDisconnected();
        } else if (pc.iceConnectionState === "connected") {
          console.log("ICE connection established");
        } else {
          console.log(pc.iceConnectionState);
        }
      };

      localStream.getTracks().forEach((track) => {
        pc.addTrack(track, localStream);
      });

      const setupCall = async () => {
        try {
          const fetchedCallData = await getDoc(callDoc);
          const existingCall = fetchedCallData.data();

          if (!existingCall) {
            console.log("No existing call found");
            return;
          }

          if (!existingCall.offer && existingCall.createdBy === userId) {
            // Caller (create offer)
            console.log("Creating offer");
            const offerDescription = await pc.createOffer();
            await pc.setLocalDescription(offerDescription);
            await updateDoc(callDoc, {
              offer: { type: offerDescription.type, sdp: offerDescription.sdp },
              createdBy: userId,
              participant1Name: displayName,
              participant1Id: userId,
              participant1ProfilePicture: profilePicture, // Include profilePicture
            });
          } else if (
            existingCall.offer &&
            !existingCall.answer &&
            existingCall.createdBy !== userId
          ) {
            // Callee (respond to offer)
            console.log("Setting remote description and creating answer");
            await pc.setRemoteDescription(
              new RTCSessionDescription(existingCall.offer)
            );
            const answerDescription = await pc.createAnswer();
            await pc.setLocalDescription(answerDescription);
            console.log("Updating Firestore document with participant name and profile picture:");
            await updateDoc(callDoc, {
              answer: {
                type: answerDescription.type,
                sdp: answerDescription.sdp,
              },
              answeredBy: userId,
              participant2Name: displayName,
              participant2Id: userId,
              participant2ProfilePicture: profilePicture, // Include profilePicture
            });

            // Set remote display name, ID, and profile picture from the offer creator
            console.log("Setting remote user info:", existingCall.participant1Name, existingCall.participant1Id, existingCall.participant1ProfilePicture);
            setRemoteUserInfo({
              name: existingCall.participant1Name,
              id: existingCall.participant1Id,
              profilePicture: existingCall.participant1ProfilePicture,
            });

            // Notify about connected call with remote user ID
            callbacks?.onCallConnected?.(existingCall.participant1Id);
          }
        } catch (error) {
          console.error("Error setting up call:", error);
        }
      };

      const unsubscribeCall = onSnapshot(callDoc, (snapshot) => {
        const data = snapshot.data();
        if (data && data.answer && data.answeredBy !== userId) {
          if (!pc.remoteDescription) {
            console.log("Received answer, setting remote description");
            const answerDescription = new RTCSessionDescription(data.answer);
            pc.setRemoteDescription(answerDescription).catch((error) => {
              console.error("Error setting remote description:", error);
            });
          }
          // Set remote user info from answer
          console.log("Setting remote user info:", data.participant2Name, data.participant2Id, data.participant2ProfilePicture);
          setRemoteUserInfo({
            name: data.participant2Name || "Remote User",
            id: data.participant2Id || "unknown-id",
            profilePicture: data.participant2ProfilePicture || "",
          });

          // Notify about connected call with remote user ID (for caller)
          callbacks?.onCallConnected?.(data.participant2Id);
        }
      });

      const unsubscribeCandidates = onSnapshot(
        candidatesCollection,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const data = change.doc.data();
              if (
                data &&
                data.candidate &&
                data.sdpMid &&
                data.sdpMLineIndex !== undefined &&
                data.userId &&
                data.userId !== userId
              ) {
                const candidate = new RTCIceCandidate({
                  candidate: data.candidate,
                  sdpMid: data.sdpMid,
                  sdpMLineIndex: data.sdpMLineIndex,
                  usernameFragment: data.usernameFragment,
                });
                console.log("Adding ICE candidate");
                pc.addIceCandidate(candidate).catch((err) =>
                  console.error("Error adding ICE candidate:", err)
                );
              } else if (data.userId === userId) {
                console.log("Skipping own ICE candidate");
              } else {
                console.warn("Received invalid ICE candidate data:", data);
              }
            }
          });
        }
      );

      setupCall();

      return () => {
        unsubscribeCall();
        unsubscribeCandidates();
        if (peerConnectionRef.current) {
          peerConnectionRef.current.close();
          peerConnectionRef.current = null;
        }
      };
    },
    [userId, displayName, profilePicture, sendCandidatesToFirebase, callbacks]
  );

  const cleanupWebRTC = useCallback(() => {
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
    candidatesBuffer.current = [];
    if (candidatesTimeoutRef.current) {
      clearTimeout(candidatesTimeoutRef.current);
      candidatesTimeoutRef.current = null;
    }
  }, []);

  return { setupWebRTC, cleanupWebRTC };
};
