import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import './BadgeModal.css';

const getLevelColor = (level) => {
  switch (level.toLowerCase()) {
    case 'bronze':
      return '#cd7f32';
    case 'silver':
      return '#c0c0c0';
    case 'gold':
      return '#ffd700';
    case 'vibranium':
      return '#4B0082';
    default:
      return '#4a47a3';
  }
};

const BadgeModal = ({ isOpen, onRequestClose, badge, badgeDetails }) => {
  const { t } = useTranslation();
  
  if (!badge || !badgeDetails) return null;

  const badgeKey = badge.badge || badge.badge_name;
  const currentLevel = badge.level;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Badge Details"
      className="badge-modal"
      overlayClassName="badge-modal-overlay"
    >
      <div className="badge-modal-content">
        <img 
          src={badgeDetails[badgeKey]?.image} 
          alt={t(`badges.${badgeKey}.name`)} 
          className="badge-modal-image" 
        />
        <h2>{t(`badges.${badgeKey}.name`)}</h2>
        <p>{t(`badges.${badgeKey}.baseDescription`)}</p>
        {badgeDetails[badgeKey]?.levels && (
          <div className="badge-levels">
            {Object.keys(badgeDetails[badgeKey].levels).map((level) => (
              <div 
                key={level} 
                className="badge-level-info" 
                style={{ color: getLevelColor(level) }}
              >
                <h3>
                  {t(`badges.${badgeKey}.levels.${level}.name`)}
                  {level.toLowerCase() === currentLevel.toLowerCase() 
                    ? ' (Current Level)' 
                    : ''}
                </h3>
                <p>{t(`badges.${badgeKey}.levels.${level}.next`)}</p>
              </div>
            ))}
          </div>
        )}
        <button onClick={onRequestClose} className="close-modal-button">
          {t('common.close')}
        </button>
      </div>
    </Modal>
  );
};

export default BadgeModal;