import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext
import './Register.css'; // Reuse the existing CSS file

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',

};

function RegisterWithGoogle() {
  const location = useLocation();
  const { login } = useContext(AuthContext); // Get the login function from AuthContext
  const [formData, setFormData] = useState({
    display_name: '',
    email: new URLSearchParams(location.search).get('email') || '',
    interests: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Get selected languages from the URL and set them in state
    const languagesParam = new URLSearchParams(location.search).get('languages');
    console.log('Languages param from URL:', languagesParam); // Log the languages parameter
    if (languagesParam) {
      setFormData(prevData => ({
        ...prevData,
        interests: languagesParam.split(','),
      }));
      console.log('Parsed languages:', languagesParam.split(',')); // Log the parsed languages
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${apiUrl}/register_with_google`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('User registered:', data);
          login(data.user); // Log in the user
          alert('User registered successfully');
          navigate('/home'); // Redirect to home
        } else {
          alert('Error: ' + data.message);
        }
      })
      .catch(error => {
        console.error('Error registering user:', error);
      });
  };

  return (
    <div className="register-container">
      <div
        className="register-left"
        style={{
          backgroundImage: `url('/images/register-left.jpeg')`,
        }}
      >
        <div className="register-left-content"></div>
      </div>
      <div className="register-right">
        <Link to="/home">
          <img src="/images/speakeasylogo.png" alt="SpeakEasy Logo" className="sign-in-logo" />
        </Link>
        <h2 className="register-title">Complete Your Registration</h2>
        <form onSubmit={handleSubmit} className="register-form">
          <div className="register-form-group">
            <label htmlFor="display_name" className="register-label">Display Name:</label>
            <input
              type="text"
              id="display_name"
              name="display_name"
              value={formData.display_name}
              onChange={handleChange}
              required
              className="register-input"
            />
          </div>

          {/* Display selected languages */}
          <h3 className="register-languages-title">Your Selected Languages</h3>
          <div className="register-languages">
            {formData.interests.map((language) => (
              <div key={language} className="register-language-item">
                <img src={languageImages[language]} alt={language} className="register-language-img" />
                <p>{language}</p>
              </div>
            ))}
          </div>

          <button type="submit" className="register-get-started-button">Complete Registration</button>
        </form>
      </div>
    </div>
  );
}

export default RegisterWithGoogle;
