import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight, faGift, faLightbulb, faBrain, faStairs } from '@fortawesome/free-solid-svg-icons';
import './WordPackIntroModal.css';

const WordPackIntroModal = ({ isOpen, onClose, language }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      icon: faGift,
      title: "Welcome to Word Packs!",
      content: "We're excited to introduce you to a smarter way of learning languages."
    },
    {
      icon: faLightbulb,
      title: "Why Phrases Instead of Words?",
      content: "At Speakeasy, we believe that memorizing individual words isn't the most effective way to learn a language. Instead, we focus on practical phrases you'll actually use in real conversations."
    },
    {
      icon: faBrain,
      title: "Learn Like a Native",
      content: "Our phrases are carefully selected to help you express yourself naturally. Here's an example conversation starter:",
      examples: [
        {
          phrase: "はじめまして (Hajimemashite)",
          translation: "Nice to meet you"
        },
        {
          phrase: "アメリカからです (Amerika kara desu)",
          translation: "I am from America"
        },
        {
          phrase: "日本語を勉強しています (Nihongo o benkyō shiteimasu)",
          translation: "I am learning Japanese"
        }
      ]
    },
    {
      icon: faStairs,
      title: "Building Natural Conversations",
      content: "Master a phrase, then master the response to it. Here's how a native speaker might respond:",
      conversation: [
        {
          initial: {
            phrase: "はじめまして (Hajimemashite)",
            translation: "Nice to meet you"
          },
          response: {
            phrase: "こちらこそ、はじめまして (Kochira koso, hajimemashite)",
            translation: "Nice to meet you too"
          }
        },
        {
          initial: {
            phrase: "日本語を勉強しています (Nihongo o benkyō shiteimasu)",
            translation: "I am learning Japanese"
          },
          response: {
            phrase: "日本語が上手ですね (Nihongo ga jōzu desu ne)",
            translation: "Your Japanese is good!"
          }
        }
      ],
      footer: "As you progress, you'll learn more complex phrases and variations, building upon these fundamental expressions."
    }
  ];

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(prev => prev + 1);
    } else {
      onClose();
    }
  };

  if (!isOpen) return null;

  const currentSlideData = slides[currentSlide];

  return (
    <div className="wordpack-intro-overlay">
      <div className="wordpack-intro-modal">
        <button className="intro-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="intro-content">
          <div className="intro-icon">
            <FontAwesomeIcon icon={currentSlideData.icon} />
          </div>

          <h2 className="intro-title">{currentSlideData.title}</h2>
          
          <div className="intro-body">
            <p>{currentSlideData.content}</p>
            
            {currentSlideData.examples && (
              <div className="example-phrases">
                {currentSlideData.examples.map((example, index) => (
                  <div key={index} className="example-phrase">
                    <div className="phrase">{example.phrase}</div>
                    <div className="translation">{example.translation}</div>
                  </div>
                ))}
              </div>
            )}

            {currentSlideData.conversation && (
              <div className="conversation-examples">
                {currentSlideData.conversation.map((exchange, index) => (
                  <div key={index} className="conversation-exchange">
                    <div className="exchange-initial">
                      <div className="phrase">{exchange.initial.phrase}</div>
                      <div className="translation">{exchange.initial.translation}</div>
                    </div>
                    <div className="exchange-arrow">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    <div className="exchange-response">
                      <div className="phrase">{exchange.response.phrase}</div>
                      <div className="translation">{exchange.response.translation}</div>
                    </div>
                  </div>
                ))}
                {currentSlideData.footer && (
                  <div className="conversation-footer">
                    {currentSlideData.footer}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="intro-navigation">
            <div className="slide-dots">
              {slides.map((_, index) => (
                <div 
                  key={index} 
                  className={`dot ${currentSlide === index ? 'active' : ''}`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
            
            <button className="intro-next-button" onClick={handleNext}>
              {currentSlide === slides.length - 1 ? "Get Started" : "Next"}
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordPackIntroModal; 