import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const languages = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'ar', name: 'العربية', flag: '🇸🇦' },
    { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
    { code: 'el', name: 'Ελληνικά', flag: '🇬🇷' },
    { code: 'es', name: 'Español', flag: '🇪🇸' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' },
    { code: 'hi', name: 'हिन्दी', flag: '🇮🇳' },
    { code: 'it', name: 'Italiano', flag: '🇮🇹' },
    { code: 'ja', name: '日本語', flag: '🇯🇵' },
    { code: 'ko', name: '한국어', flag: '🇰🇷' },
    { code: 'pt', name: 'Português', flag: '🇧🇷' },
    { code: 'ru', name: 'Русский', flag: '🇷🇺' },
    { code: 'tl', name: 'Tagalog', flag: '🇵🇭' },
    { code: 'yue', name: '粵語', flag: '🇭🇰' },
    { code: 'zh', name: '中文', flag: '🇨🇳' },
  ];

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLang = e.target.value;
    try {
      // Change language in i18next
      i18n.changeLanguage(newLang);
      
      // Backup storage
      localStorage.setItem('i18nextLng', newLang);
      document.cookie = `preferredLanguage=${newLang}; max-age=31536000; path=/`;
      
      // Optional: Save to user preferences if logged in
      // if (isAuthenticated) {
      //   api.updateUserLanguage(newLang).catch(console.warn);
      // }
    } catch (error) {
      console.warn('Failed to set language preference:', error);
      // Attempt cookie-only storage if localStorage fails
      document.cookie = `preferredLanguage=${newLang}; max-age=31536000; path=/`;
    }
  };

  return (
    <select
      className="language-selector"
      onChange={handleLanguageChange}
      value={i18n.language}
    >
      {languages.map(({ code, name, flag }) => (
        <option key={code} value={code}>
          {flag} {name}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;
