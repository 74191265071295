import React, { useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import BadgeModal from "../Dashboard/BadgeModal";
import SearchModal from "../Chat/SearchModal";
import "./Account.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: "/images/mexico_flag.png",
  Japanese: "/images/icon_flag-ja.svg",
  Portuguese: "/images/icon_flag-pt.svg",
  French: "/images/icon_flag-fr.svg",
  German: "/images/icon_flag-de.svg",
  Italian: "/images/icon_flag-it.svg",
  Mandarin: "/images/icon_flag-zh.svg",
  Arabic: "/images/icon_flag-ar.svg",
  Cantonese: "/images/icon_flag-hk.png",
  Korean: "/images/icon_flag-ko.svg",
  Russian: "/images/icon_flag-ru.svg",
  Tagalog: "/images/icon_flag_pp.png",
  Greek: "/images/icon_flag_gr.png",
  Hindi: "/images/icon_flag_in.png",
};

function Account() {
  const { user, setUser, logout } = useContext(AuthContext);
  const [friends, setFriends] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [badges, setBadges] = useState([]);
  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isRequestSearchModalOpen, setIsRequestSearchModalOpen] = useState(false);
  const [isUpdatingProfilePic, setIsUpdatingProfilePic] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchUserData = useCallback(async () => {
    if (!user || !user.id) return;
    
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/user_data/${user.id}`);
      const data = await response.json();
      if (data.success) {
        setFriends(data.friends);
        setPendingRequests(data.friend_requests);
        setBadges(data.badges);
        // Only update user if there are changes
        if (JSON.stringify(data.user) !== JSON.stringify(user)) {
          setUser(data.user);
        }
      } else {
        console.error("Failed to fetch user data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  }, [user, setUser]); // Removed apiUrl from the dependency array

  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);

  const handleProfilePicUpdate = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUpdatingProfilePic(true);

    const formData = new FormData();
    formData.append("profile_picture", file);

    const userId = user.id;

    try {
      const response = await fetch(`${apiUrl}/update_profile_picture?user_id=${userId}`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      
      if (data.success) {
        alert("Profile picture updated successfully");
        setUser((prevUser) => ({
          ...prevUser,
          profile_picture: data.file_url,
        }));
      } else {
        alert("Failed to update profile picture");
      }
    } catch (error) {
      console.error("Error updating profile picture:", error);
      alert("An error occurred while updating the profile picture");
    } finally {
      setIsUpdatingProfilePic(false);
    }
  };

  const handleProfileClick = (id) => {
    navigate(`/public_profile/${id}`);
  };

  const handleLogout = () => {
    logout(navigate);
  };

  const handleBadgeClick = (badge) => {
    if (badge) {
      setSelectedBadge(badge);
      setBadgeModalIsOpen(true);
    }
  };

  const closeBadgeModal = () => {
    setBadgeModalIsOpen(false);
  };

  const handleAcceptRequest = async (requestId) => {
    try {
      const response = await fetch(`${apiUrl}/accept_friend_request`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ request_id: requestId }),
      });
      const data = await response.json();
      if (data.success) {
        fetchUserData(); // Refresh all data after accepting request
      } else {
        console.error("Failed to accept friend request:", data.message);
      }
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      const response = await fetch(`${apiUrl}/reject_friend_request`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ request_id: requestId }),
      });
      const data = await response.json();
      if (data.success) {
        fetchUserData(); // Refresh all data after rejecting request
      } else {
        console.error("Failed to reject friend request:", data.message);
      }
    } catch (error) {
      console.error("Error rejecting friend request:", error);
    }
  };

  const getProfilePictureSrc = (profilePicture, userId) => {
    if (
      profilePicture.startsWith("uploads/") ||
      profilePicture.startsWith("http://")
    ) {
      return `${apiUrl}/get_profile_picture/${userId}`;
    }
    return profilePicture;
  };

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const handleFriendSelect = (friend) => {
    handleProfileClick(friend.id);
    closeSearchModal();
  };

  const openRequestSearchModal = () => {
    setIsRequestSearchModalOpen(true);
  };

  const closeRequestSearchModal = () => {
    setIsRequestSearchModalOpen(false);
  };

  const handleRequestSelect = (request) => {
    handleProfileClick(request.from_user_id);
    closeRequestSearchModal();
  };

  if (loading) {
    return <div>{t('account.loading')}</div>;
  }

  if (!user) {
    return (
      <p className="not-signed-in">
        {t('account.signInRequired')}
      </p>
    );
  }

  return (
    <div className="account-page fade-in">
      <div className="account-container">
        <h1>
          {t('account.title')}
          {user.is_verified && (
            <img 
              src="/images/verified-badge.webp" 
              alt="Verified User" 
              className="verified-badge" 
              title="Verified Account"
            />
          )}
          {user.is_pro && (
            <span className="pro-badge" title="Pro User">PRO</span>
          )}
        </h1>
        <div className="profile-details">
          <div className="profile-img">
            <img
              src={getProfilePictureSrc(user.profile_picture, user.id)}
              alt={user.display_name}
            />
          </div>
          <div className="profile-info">
            <p>
              <strong>{t('account.profile.name')}:</strong> {user.display_name}
            </p>
            <p>
              <strong>{t('account.profile.email')}:</strong> {user.email}
            </p>
          </div>
        </div>

        <div className="button-container">
          <div className="left-buttons">
          <label htmlFor="file-upload" className="account-action-btn">
          {isUpdatingProfilePic ? t('account.profile.updating') : t('account.profile.updatePicture')}
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleProfilePicUpdate}
              style={{ display: 'none' }}
            />
          </div>
          <button onClick={handleLogout} className="acc-button logout-button">
            {t('account.buttons.logout')}
          </button>
        </div>
      </div>
      <div className="friends-list-container">
        <div className="friends-header">
          <h2>{t('account.friends.title')} <FontAwesomeIcon 
            icon={faSearch} 
            className="search-icon account-search-icon" 
            onClick={openSearchModal}
          /></h2>
        </div>
        {friends.length > 0 ? (
          <div className="friends-grid">
            {friends.map((friend) => (
              <div key={friend.id} className="friend-card" onClick={() => handleProfileClick(friend.id)}>
                <div className="friend-pic">
                  {friend.profile_picture ? (
                    <img
                      src={getProfilePictureSrc(
                        friend.profile_picture,
                        friend.id
                      )}
                      alt={`${friend.display_name}'s profile`}
                    />
                  ) : (
                    <div className="friend-placeholder">
                      {friend.display_name.charAt(0)}
                    </div>
                  )}
                </div>
                <div className="friend-info">
                  <h3>
                    {friend.display_name}
                    {friend.is_verified && (
                      <img 
                        src="/images/verified-badge.webp" 
                        alt="Verified User" 
                        className="verified-badge" 
                        title="Verified Account"
                      />
                    )}
                    {friend.is_pro && (
                      <span className="pro-badge" title="Pro User">PRO</span>
                    )}
                  </h3>
                  <div className="languages-container-account">
                    {friend.languages.slice(0, 4).map((lang) => (
                      <img
                        key={lang.language}
                        src={languageImages[lang.language]}
                        alt={lang.language}
                        className="language-flag-account"
                      />
                    ))}
                    {friend.languages.length > 4 && (
                      <span className="more-languages">
                        {t('account.friends.moreLanguages', { count: friend.languages.length - 4 })}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>{t('account.friends.noFriends')}</p>
        )}
      </div>
      <div className="pending-requests-container">
        <div className="requests-header">
          <h2>{t('account.requests.title')}</h2>
          <FontAwesomeIcon 
            icon={faSearch} 
            className="search-icon account-search-icon" 
            onClick={openRequestSearchModal}
          />
        </div>
        {pendingRequests.length > 0 ? (
          <div className="pending-requests-grid">
            {pendingRequests.map((request) => (
              <div
                key={request.id}
                className="pending-request-card"
                onClick={() => handleProfileClick(request.from_user_id)}
              >
                <div className="friend-pic">
                  {request.from_user_profile_picture ? (
                    <img
                      src={getProfilePictureSrc(
                        request.from_user_profile_picture,
                        request.from_user_id
                      )}
                      alt={`${request.from_user_name}'s profile`}
                    />
                  ) : (
                    <div className="friend-placeholder">
                      {request.from_user_name.charAt(0)}
                    </div>
                  )}
                </div>
                <h3>{request.from_user_name}</h3>
                <div className="request-actions">
                  <button
                    className="accept-button"
                    onClick={() => handleAcceptRequest(request.id)}
                  >
                    {t('account.requests.actions.accept')}
                  </button>
                  <button
                    className="reject-button"
                    onClick={() => handleRejectRequest(request.id)}
                  >
                    {t('account.requests.actions.reject')}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>{t('account.requests.noRequests')}</p>
        )}
      </div>
      <div className="account-badges-container">
        <h2>{t('account.badges.title')}</h2>
        <div className="account-badges-grid">
          {badges.map((badge) => (
            <div
              key={badge.badge_name}
              className={`account-badge-card ${
                badge.level ? badge.level : "locked"
              }`}
              onClick={() => handleBadgeClick(badge)}
            >
              <img
                src={badge.badge_image}
                alt={badge.badge_name}
                className="account-badge-image"
              />
            </div>
          ))}
        </div>
      </div>

      <BadgeModal
        isOpen={badgeModalIsOpen}
        onRequestClose={closeBadgeModal}
        badge={selectedBadge}
      />

      <SearchModal
        isOpen={isSearchModalOpen}
        onClose={closeSearchModal}
        onUserSelect={handleFriendSelect}
        searchType="friends"
        userId={user.id}
      />

      <SearchModal
        isOpen={isRequestSearchModalOpen}
        onClose={closeRequestSearchModal}
        onUserSelect={handleRequestSelect}
        searchType="friend_requests"
        userId={user.id}
      />
      <LanguageSelector />
    </div>
  );
}

export default Account;