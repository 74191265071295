const BACKEND_API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

class OpenAIService {
  async generatePhrasesForContext(language, context, level) {
    try {
      const response = await fetch(`${BACKEND_API_URL}/word_packs/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language,
          context,
          level,
        }),
      });

      if (response.status === 429) {
        throw new Error('Rate limit exceeded. Please wait a moment before trying again.');
      }

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || 'Failed to generate phrases');
      }

      const data = await response.json();
      return data.phrases;
    } catch (error) {
      console.error('OpenAI API Error:', error);
      throw error;
    }
  }

  async generateVocabPairs(language, level) {
    try {
      const response = await fetch(`${BACKEND_API_URL}/get_word_pairs/${language}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          level: level,
          language: language
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || 'Failed to generate word pairs');
      }

      const data = await response.json();
      
      if (!data.wordPairs || !Array.isArray(data.wordPairs)) {
        console.error('Invalid response format:', data);
        throw new Error('Invalid word pairs data received');
      }

      return data.wordPairs;
    } catch (error) {
      console.error('Vocab Race API Error:', error);
      throw error;
    }
  }
}

const openAIService = new OpenAIService();
export default openAIService; 