
const apiUrl = process.env.REACT_APP_API_BASE_URL; // Ensure this environment variable is set

/**
 * Returns the correct profile picture URL.
 * If the profile picture URL starts with 'http', it returns the URL as is.
 * Otherwise, it prefixes the URL with the API base URL.
 *
 * @param profilePicture - The profile picture URL or path.
 * @returns The full URL to the profile picture.
 */
export const getProfilePictureSrc = (profilePicture: string): string => {
  return profilePicture.startsWith('http') ? profilePicture : `${apiUrl}/${profilePicture}`;
};