import React from 'react';
import Modal from 'react-modal';
import './WelcomeModal.css'; // We'll handle the styling here

Modal.setAppElement('#root'); // This is important for screen readers

const WelcomeModal = ({ isOpen, onStartTour, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="welcome-modal"
      overlayClassName="welcome-modal-overlay"
    >
      <h2 className="modal-title">Welcome to Speakeasy!</h2>
      <p className="modal-text">
        We're excited to have you on board. Would you like a quick tour of the app to help you get started?
      </p>
      <div className="modal-buttons">
        <button className="getstartedwithlanguage-button" onClick={onStartTour}>Start Tour</button>
        <button className="home-get-started-button-2" onClick={onClose}>No Thanks</button>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
