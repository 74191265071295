// src/index.js

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import AppWrapper from "./routes/Home/App";
import { AuthProvider } from "./contexts/AuthContext";
import { RecoilRoot } from "recoil";

const root = createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <AuthProvider>
      <AppWrapper />
    </AuthProvider>
  </RecoilRoot>
);
