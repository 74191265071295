import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";

export const findOrCreateCall = async (
  language: string,
  user: any,
  callback: (callId: string, otherUserId?: string) => void
) => {
  const callsRef = collection(firestore, "calls");
  const q = query(
    callsRef,
    where("language", "==", language),
    where("participants", "==", 1)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // Join the first available call
    const callDoc = querySnapshot.docs[0];
    const existingCall = callDoc.data();
    await updateDoc(doc(callsRef, callDoc.id), {
      participants: 2,
      participant2Name: user.display_name,
      participant2Id: user.id
    });
    callback(callDoc.id, existingCall.createdBy); // Pass the creator's ID
    return;
  }

  // Create a new call
  const newCallRef = doc(callsRef);
  await setDoc(newCallRef, {
    language,
    participants: 1,
    createdBy: user.id,
    participant1Name: user.display_name,
    participant1Id: user.id
  });
  callback(newCallRef.id);
};
