import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import openAIService from '../../services/openAIService';
import './VocabularyRace.css';
import { useTranslation } from 'react-i18next';

const VocabularyRace = () => {
  const { user } = useContext(AuthContext);
  const { language } = useParams();
  const [wordPairs, setWordPairs] = useState([]);
  const [shuffledWords, setShuffledWords] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [matches, setMatches] = useState([]);
  const [time, setTime] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState('easy');
  const [popUpMessage, setPopUpMessage] = useState('');
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isStarted && !isFinished) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isStarted, isFinished]);

  const startGame = async () => {
    setIsLoading(true);
    try {
      await fetchWordPairs(language, selectedLevel);
      setIsStarted(true);
      setIsFinished(false);
      setTime(0);
    } catch (error) {
      showPopUp("Failed to start game. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const shuffleArray = (array) => {
    // Fisher-Yates shuffle algorithm
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const fetchWordPairs = async (language, level) => {
    try {
      const wordPairs = await openAIService.generateVocabPairs(language, level);
      
      if (!wordPairs || wordPairs.length === 0) {
        throw new Error('No word pairs received');
      }
      
      setWordPairs(wordPairs);
      setMatches([]); // Reset matches for a new game

      // Create a flat array of all words and translations
      const allWords = wordPairs.flatMap(pair => [
        { text: pair.word, language: 'en' },
        { text: pair.translation, language: language.toLowerCase() }
      ]);

      // Shuffle the array
      const shuffled = shuffleArray(allWords);

      // Set the shuffled words in state
      setShuffledWords(shuffled);
    } catch (error) {
      console.error('Error fetching word pairs:', error);
      showPopUp("Failed to load words. Please try again.");
      setIsStarted(false); // Reset game state on error
    }
  };

  const handleWordClick = (word, language) => {
    if (selectedWord) {
      if (selectedWord.language === language) {
        // Incorrect match due to same language
        document.getElementById(selectedWord.text).classList.add('incorrect');
        document.getElementById(word).classList.add('incorrect');
  
        setTimeout(() => {
          document.getElementById(selectedWord.text).classList.remove('incorrect', 'selected');
          document.getElementById(word).classList.remove('incorrect');
          setSelectedWord(null);
        }, 300);
  
      } else {
        const isMatch = wordPairs.some(pair =>
          (pair.word === selectedWord.text && pair.translation === word) ||
          (pair.translation === selectedWord.text && pair.word === word)
        );
  
        setMatches((prevMatches) => {
          // Filter out previous incorrect match if it exists
          const updatedMatches = prevMatches.filter(
            match => match.word1 !== selectedWord.text && match.word2 !== word
          );
  
          // Add the new match
          return [
            ...updatedMatches,
            { word1: selectedWord.text, word2: word, isCorrect: isMatch }
          ];
        });
  
        if (isMatch) {
          document.getElementById(selectedWord.text).classList.add('correct');
          document.getElementById(word).classList.add('correct');
        } else {
          document.getElementById(selectedWord.text).classList.add('incorrect');
          document.getElementById(word).classList.add('incorrect');
  
          setTimeout(() => {
            document.getElementById(selectedWord.text).classList.remove('incorrect', 'selected');
            document.getElementById(word).classList.remove('incorrect');
          }, 300);
        }
  
        setSelectedWord(null);
      }
    } else {
      setSelectedWord({ text: word, language });
      document.getElementById(word).classList.add('selected');
    }
  };

  const showPopUp = (message) => {
    setPopUpMessage(message);
    setIsPopUpVisible(true);
    setTimeout(() => {
      setIsPopUpVisible(false);
    }, 3000); // Hide pop-up after 3 seconds
  };

  const handleFinish = async () => {
    const correctMatchesCount = matches.filter(match => match.isCorrect).length;
  
    if (correctMatchesCount === wordPairs.length) {
      // If all matches are correct
      setIsFinished(true);
  
      const response = await fetch(`${apiUrl}/validate_matches/${language}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          matches, 
          time, 
          user_id: user.id, 
          level: selectedLevel,
          word_pairs: wordPairs
        }),
      });
      const result = await response.json();
  
      if (result.correct) {
        setTimeout(() => {
          setIsStarted(false);
          setIsFinished(false);
        }, 2000);
      } else {
        showPopUp("Some matches are incorrect. Please try again.");
        setIsFinished(false);
        return;
      }
    } else {
      // If not all matches are correct
      showPopUp(`You need ${wordPairs.length - correctMatchesCount} more correct match(es).`);
    }
  };
  

  return (
    <div className={`vocabulary-race-container ${isStarted ? 'game-started' : ''}`}>
      {!isStarted ? (
        <div className="vocabulary-race-intro">
          <h1 className="vocabulary-race-title">{t('games.vocabularyRace.title')}</h1>
          <div className="vocabulary-race-logo-container">
            <img src="/images/vocab_race_icon_2.webp" alt={t('games.vocabularyRace.title')} className="vocabulary-race-logo" />
          </div>
          <p className="vocabulary-race-description">
            {t('games.vocabularyRace.description')}
          </p>
          <ul className="vocabulary-race-rules">
            {t('games.vocabularyRace.rules.items', { returnObjects: true }).map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
          <h3 className="vocabulary-race-difficulty">{t('games.vocabularyRace.difficulty.title')}</h3>
          <div className="level-buttons">
            {['easy', 'medium', 'hard'].map((level) => (
              <button
                key={level}
                onClick={() => setSelectedLevel(level)}
                className={`level-button ${selectedLevel === level ? 'selected' : ''}`}
              >
                {level}
              </button>
            ))}
          </div>
          <button 
            onClick={startGame} 
            className="vocabulary-race-button"
            disabled={isLoading}
          >
            {isLoading ? 'Loading Words...' : 'Start Game'}
          </button>
        </div>
      ) : (
        <>
          {!isFinished ? (
            <div className="vocabulary-race-game-container">
              <div className="timer">Time: {time} seconds</div>
              {shuffledWords.length > 0 ? (
                <div className="word-pairs-grid">
                  {shuffledWords.map((wordObj, index) => (
                    <div
                      key={index}
                      id={wordObj.text}
                      className="word-item"
                      onClick={() => handleWordClick(wordObj.text, wordObj.language)}
                    >
                      {wordObj.text}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="loading-container">
                  <p>Loading words...</p>
                </div>
              )}
              <button onClick={handleFinish} className="vocabulary-race-button">
                Finish
              </button>
              {isPopUpVisible && (
                <div className="popup-container">
                  <div className="popup-message">
                    <p>{popUpMessage}</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="vocabulary-race-finish">
              <h2>Game Finished!</h2>
              <p>Your time: {time} seconds</p>
              <button onClick={startGame} className="vocabulary-race-button">
                Play Again
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VocabularyRace;