class SpeechService {
  constructor() {
    this.isPlaying = false;
    this.currentAudio = null;
  }

  async speak(text, language) {
    if (this.isPlaying) {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      this.isPlaying = false;
      return;
    }

    try {
      this.isPlaying = true;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text,
          language,
          user_id: localStorage.getItem('userId')
        }),
      });

      if (!response.ok) {
        throw new Error(`TTS request failed: ${response.statusText}`);
      }

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      this.currentAudio = new Audio(audioUrl);

      return new Promise((resolve, reject) => {
        this.currentAudio.onended = () => {
          URL.revokeObjectURL(audioUrl);
          this.isPlaying = false;
          this.currentAudio = null;
          resolve();
        };

        this.currentAudio.onerror = (error) => {
          URL.revokeObjectURL(audioUrl);
          this.isPlaying = false;
          this.currentAudio = null;
          reject(error);
        };

        this.currentAudio.play();
      });
    } catch (error) {
      this.isPlaying = false;
      this.currentAudio = null;
      console.error('TTS error:', error);
      throw error;
    }
  }

  getVoicesForLanguage() {
    return [];
  }
}

const speechService = new SpeechService();
export default speechService; 