import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from "react";
import { collection, addDoc, onSnapshot, query, orderBy, serverTimestamp, updateDoc, doc, arrayUnion, where, getDocs } from "firebase/firestore";
import { firestore } from "../../config/firebase";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCog, faEllipsisH, faReply, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import "./Chat.css";
import MembersModal from "./MembersModal";
import ProgressBar from "./ProgressBar";
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Badge levels for Chat no GPT
const badgeLevels = {
  bronze: { name: "Bronze", count: 1, next: "Send 15 chat messages to unlock Silver" },
  silver: { name: "Silver", count: 15, next: "Send 50 chat messages to unlock Gold" },
  gold: { name: "Gold", count: 50, next: "Send 200 chat messages to unlock Vibranium" },
  vibranium: { name: "Vibranium", count: 200, next: "Max Level Achieved" },
};

// Mock of available languages (could be dynamic)
const languageChats = ["spanish", "arabic", "french", "german", "japanese", "portuguese", "mandarin", "italian", "cantonese", "korean", "russian", "tagalog", "greek", "hindi"];

// Determine environment for collection name
const environment = process.env.REACT_APP_ENV === "prod" ? "" : "_local";

const Chat = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { user } = useContext(AuthContext);
  const [isTyping, setIsTyping] = useState(false);
  const [replyToMessage, setReplyToMessage] = useState(null);
  const chatContainerRef = useRef(null);
  const typingTimeout = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showReactions, setShowReactions] = useState(null);
  const [activeMessageId, setActiveMessageId] = useState(null);

  const [totalMessageCount, setTotalMessageCount] = useState(0); // Track total user message count across all languages
  const [badgeProgress, setBadgeProgress] = useState({ level: "Bronze", progress: 0, nextLevel: "Silver", nextCount: 15 });
  const [isInputVisible, setIsInputVisible] = useState(true);
  const lastScrollTop = useRef(0);

  // Add this new state
  const [fullMemberDetails, setFullMemberDetails] = useState({});

  // Add this useEffect at the top of your component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Disable scrolling
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // Helper function to get total message count across all languages
  const getMessageCountAndLevel = async (userId, displayName) => {
    const fetchMessageCounts = languageChats.map(async (language) => {
      const q = query(
        collection(firestore, "chats", language, `messages${environment}`),
        where("user_id", "==", userId),
        where("display_name", "==", displayName)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    });

    const messageCounts = await Promise.all(fetchMessageCounts);
    const messageCount = messageCounts.reduce((acc, count) => acc + count, 0);
    return messageCount;
  };

  // Fetch user's total messages across all languages and update badge progress
  useEffect(() => {
    const fetchTotalMessages = async () => {
      if (user) {
        const messageCount = await getMessageCountAndLevel(user.id, user.display_name);
        setTotalMessageCount(messageCount);
        updateBadgeProgress(messageCount); // Update badge progress based on the total messages
      }
    };

    fetchTotalMessages();
  }, [user]);

  // Fetch messages for the specific language chat room
  useEffect(() => {
    const q = query(
      collection(firestore, "chats", language, `messages${environment}`),
      orderBy("timestamp", "asc") // Keep ascending order
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const languageMessages = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setMessages(languageMessages);
    });

    return () => unsubscribe();
  }, [language]);

  // Scroll to bottom of chat when messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Calculate and update badge progress based on total message count
  const updateBadgeProgress = (count) => {
    if (count >= badgeLevels.vibranium.count) {
      setBadgeProgress({ level: "Vibranium", progress: 100, nextLevel: null, nextCount: 200 });
    } else if (count >= badgeLevels.gold.count) {
      setBadgeProgress({ level: "Gold", progress: (count / 200) * 100, nextLevel: "Vibranium", nextCount: 200 });
    } else if (count >= badgeLevels.silver.count) {
      setBadgeProgress({ level: "Silver", progress: (count / 50) * 100, nextLevel: "Gold", nextCount: 50 });
    } else if (count >= badgeLevels.bronze.count) {
      setBadgeProgress({ level: "Bronze", progress: (count / 15) * 100, nextLevel: "Silver", nextCount: 15 });
    }
  };

  const handleTyping = (e) => {
    setNewMessage(e.target.value);
    setIsTyping(true);

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    typingTimeout.current = setTimeout(() => setIsTyping(false), 1000);
  };

  const sendMessage = async () => {
    if (newMessage.trim() && user) {
      const userProfile = {
        user_id: user.id,
        display_name: user.display_name,
        profile_picture: user.profile_picture,
      };

      const messageData = {
        text: newMessage,
        timestamp: serverTimestamp(),
        ...userProfile,
        environment: environment,
        language,
      };

      if (replyToMessage) {
        messageData.replyTo = {
          id: replyToMessage.id,
          text: replyToMessage.text,
          display_name: replyToMessage.display_name,
          user_id: replyToMessage.user_id,
        };

        // Send notification if the replied-to user is not the current user
        if (replyToMessage.user_id !== user.id) {
          const messagePreview = newMessage.length > 50 ? newMessage.substring(0, 50) + '...' : newMessage;
          await sendReplyNotification(replyToMessage.user_id, user.display_name, messagePreview);
        }
      }

      try {
        await addDoc(
          collection(firestore, "chats", language, `messages${environment}`),
          messageData
        );
        setNewMessage("");
        setReplyToMessage(null);
        
        // Update message count and progress immediately
        await updateMessageCountAndProgress();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const addReaction = async (messageId, reaction) => {
    const messageRef = doc(firestore, "chats", language, `messages${environment}`, messageId);
    try {
      await updateDoc(messageRef, {
        reactions: arrayUnion({ user_id: user.id, reaction }),
      });
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
    setShowReactions(null);
  };

  const handleReply = (message) => {
    setReplyToMessage(message);
  };

  const getProfilePictureSrc = (profilePicture) => {
    if (profilePicture.startsWith("http")) {
      return profilePicture;
    }
    return `http://127.0.0.1:5000/${profilePicture}`;
  };

  const uniqueMembers = useMemo(() => {
    return [...new Map(messages.map((msg) => [msg.user_id, msg])).values()];
  }, [messages]);

  const toggleModal = async () => {
    if (!isModalOpen && uniqueMembers.length > 0) {
      const memberIds = uniqueMembers.map(member => member.user_id);
      const details = await fetchFullMemberDetails(memberIds);
      setFullMemberDetails(details);
    }
    setIsModalOpen(!isModalOpen);
  };

  // Add this new function
  const fetchFullMemberDetails = useCallback(async (memberIds) => {
    try {
      const response = await fetch(`${apiUrl}/get_members_modals_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_ids: memberIds }),
      });
      const data = await response.json();
      if (data.success) {
        return data.users.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
    return {};
  }, []);

  // Update the membersWithStats calculation
  const membersWithStats = useMemo(() => {
    return uniqueMembers.map((member) => {
      const messageCount = messages.filter((msg) => msg.user_id === member.user_id).length;
      const fullDetails = fullMemberDetails[member.user_id] || {};
      return {
        ...member,
        ...fullDetails,
        messageCount,
        isOnline: true,
      };
    });
  }, [uniqueMembers, messages, fullMemberDetails]);

  const handleReactionClick = (msgId) => {
    setShowReactions(msgId);
    setTimeout(() => {
      setShowReactions(null);
    }, 3000);
  };

  // Function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    const date = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const diffDays = (now - date) / (1000 * 60 * 60 * 24);
    
    if (diffDays < 1) {
      // If less than a day ago, show time
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      // If more than a day ago, show date
      return date.toLocaleDateString();
    }
  };

  // New function to get a greeting based on the time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t('chat.greetings.morning');
    if (hour < 18) return t('chat.greetings.afternoon');
    return t('chat.greetings.evening');
  };

  // New function to get language-specific emoji and greeting
  const getLanguageInfo = (language) => {
    const languageInfo = {
      spanish: { emoji: "🇪🇸", greeting: "¡Hola!", secondaryEmoji: "💃" },
      arabic: { emoji: "🇸🇦", greeting: "مرحبا!", secondaryEmoji: "🕌" },
      french: { emoji: "🇫🇷", greeting: "Bonjour!", secondaryEmoji: "🗼" },
      german: { emoji: "🇩🇪", greeting: "Guten Tag!", secondaryEmoji: "🥨" },
      japanese: { emoji: "🇯🇵", greeting: "こんにちは!", secondaryEmoji: "🗻" },
      portuguese: { emoji: "🇧🇷", greeting: "Olá!", secondaryEmoji: "🎭" },
      mandarin: { emoji: "🇨🇳", greeting: "你好!", secondaryEmoji: "🐉" },
      italian: { emoji: "🇮🇹", greeting: "Ciao!", secondaryEmoji: "🎭" },
      cantonese: { emoji: "🇭🇰", greeting: "你好!", secondaryEmoji: "🏮" },
      korean: { emoji: "🇰🇷", greeting: "안녕하세요!", secondaryEmoji: "🥋" },
      russian: { emoji: "🇷🇺", greeting: "Здравствуйте!", secondaryEmoji: "💛" },
      tagalog: { emoji: "🇵🇭", greeting: "Kumusta!", secondaryEmoji: "🌴" },
      greek: { emoji: "🇬🇷", greeting: "Γεια σου!", secondaryEmoji: "🏛️" },
      hindi: { emoji: "🇮🇳", greeting: "नमस्ते!", secondaryEmoji: "🕉️" }
    };
    return languageInfo[language] || { emoji: "🌍", greeting: "Hello!", secondaryEmoji: "🗣️" };
  };

  const { emoji, greeting, secondaryEmoji } = getLanguageInfo(language);

  // Handle scroll to toggle input visibility
  useEffect(() => {
    const handleScroll = () => {
      if (!chatContainerRef.current) return;
      
      const st = chatContainerRef.current.scrollTop;
      if (st > lastScrollTop.current) {
        // Scrolling down
        setIsInputVisible(true);
      } else {
        // Scrolling up
        setIsInputVisible(false);
      }
      lastScrollTop.current = st <= 0 ? 0 : st;
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const sendReplyNotification = async (repliedToUserId, replyingUserName, messagePreview) => {
    try {
      const response = await fetch(`${apiUrl}/send_chat_reply_notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to_user_id: repliedToUserId,
          from_user_name: replyingUserName,
          message_preview: messagePreview,
          language: language,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send notification');
      }
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const updateMessageCountAndProgress = async () => {
    if (user) {
      const newMessageCount = totalMessageCount + 1;
      setTotalMessageCount(newMessageCount);
      updateBadgeProgress(newMessageCount);
    }
  };

  return (
    <div className="chat-page-container">
      <div className="chat-container">
        <div className="header">
          <div className="chat-title">
            <h1>{emoji} {greeting} {user.display_name}!</h1>
            <p>{getGreeting()}, {t('chat.header.welcome', { language: language.charAt(0).toUpperCase() + language.slice(1) })} {secondaryEmoji}</p>
          </div>
          <div className="chat-info-container">
            <div className="chat-info">
              <p>{t('chat.header.members', { count: uniqueMembers.length })}</p>
            </div>
            <div className="progress-bar-container">
              <h3>{t('chat.header.chatLevel', { level: badgeProgress.level })}</h3>
              <ProgressBar progress={badgeProgress.progress} />
              {badgeProgress.nextLevel && (
                <p>
                  <FontAwesomeIcon icon={faStarHalfAlt} /> 
                  {t('chat.badges.next', {
                    level: badgeProgress.nextLevel,
                    current: totalMessageCount,
                    required: badgeProgress.nextCount
                  })}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="chat-messages-container">
          <div className="members-navbar">
            <div className="left-section">
              <span className="title" onClick={toggleModal}>
                {t('chat.members.title')}
              </span>
              {uniqueMembers.map((member, index) => (
                <img
                  key={index}
                  src={getProfilePictureSrc(member.profile_picture)}
                  alt={member.display_name}
                  className="attendee-profile-pic"
                />
              ))}
            </div>
            <div className="right-section">
              <FontAwesomeIcon icon={faCog} title={t('chat.members.settings')} />
            </div>
          </div>

          <div className="chat-messages" ref={chatContainerRef}>
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`chat-message ${msg.user_id === user.id ? "own-message" : ""} ${
                  activeMessageId === msg.id ? "active" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveMessageId(msg.id);
                }}
              >
                <img src={getProfilePictureSrc(msg.profile_picture)} alt={msg.display_name} className="profile-pic" />
                <div className="message-content">
                  {msg.replyTo && (
                    <div className="reply-context">
                      <FontAwesomeIcon icon={faReply} />
                      <span className="reply-display-name">{msg.replyTo.display_name}</span>: {msg.replyTo.text}
                    </div>
                  )}
                  <span className="display-name">{msg.display_name}</span>
                  <span>{msg.text}</span>
                  {msg.timestamp && (
                    <span className="timestamp">{formatTimestamp(msg.timestamp)}</span>
                  )}
                  {msg.reactions && (
                    <div className="message-reactions">
                      {msg.reactions.map((reaction, index) => (
                        <span key={index} className="reaction">
                          {reaction.reaction}
                        </span>
                      ))}
                    </div>
                  )}
                  {activeMessageId === msg.id && (
                    <div className="message-options">
                      <FontAwesomeIcon icon={faEllipsisH} onClick={() => handleReactionClick(msg.id)} />
                      {showReactions === msg.id && (
                        <div className="reactions">
                          {["👍", "❤️", "😂", "😮", "😢"].map((reaction) => (
                            <span
                              key={reaction}
                              className="reaction-icon"
                              onClick={() => addReaction(msg.id, reaction)}
                            >
                              {reaction}
                            </span>
                          ))}
                          <button onClick={() => handleReply(msg)}><FontAwesomeIcon icon={faReply} /> Reply</button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
            {isTyping && <div className="typing-indicator">Someone is typing...</div>}
          </div>
        </div>

        <div className={`chat-input-container ${isInputVisible ? '' : 'slide-up'}`}>
          {replyToMessage && (
            <div className="replying-to">
              <FontAwesomeIcon icon={faReply} /> 
              {t('chat.messages.replyingTo', {
                name: replyToMessage.display_name,
                message: replyToMessage.text
              })}
              <button onClick={() => setReplyToMessage(null)}>
                {t('chat.messages.cancel')}
              </button>
            </div>
          )}
          <div className="chat-input">
            <input
              type="text"
              value={newMessage}
              onChange={handleTyping}
              onKeyPress={handleKeyPress}
              placeholder={t('chat.messages.placeholder')}
            />
            <button className="send-button" onClick={sendMessage}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>

      <MembersModal 
        isOpen={isModalOpen} 
        onClose={toggleModal} 
        members={membersWithStats} 
        fullMemberDetails={fullMemberDetails} 
      />
    </div>
  );
};

export default Chat;