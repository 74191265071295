import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Custom language detector with multiple fallbacks
const customLanguageDetector = {
  name: 'customDetector',
  lookup: () => {
    // Check localStorage first
    const storedLang = localStorage.getItem('i18nextLng');
    if (storedLang) return storedLang;

    // Check cookie as backup
    const cookieLang = document.cookie
      .split('; ')
      .find(row => row.startsWith('preferredLanguage='))
      ?.split('=')[1];
    if (cookieLang) return cookieLang;

    // Fall back to browser language
    const browserLang = navigator.language.split('-')[0];
    return browserLang;
  },
  cacheUserLanguage: (lng: string) => {
    try {
      // Store in localStorage
      localStorage.setItem('i18nextLng', lng);
      // Backup in cookie (1 year expiry)
      document.cookie = `preferredLanguage=${lng}; max-age=31536000; path=/`;
    } catch (error) {
      console.warn('Failed to cache language preference:', error);
    }
  }
};

// Register the custom detector
const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    supportedLngs: [
      'en', 'es', 'fr', 'de', 'it', 'pt', 
      'ja', 'ko', 'zh', 'ar', 'ru', 'hi', 
      'el', 'tl', 'yue'
    ],
    detection: {
      order: ['customDetector', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
    }
  });

export default i18n;
