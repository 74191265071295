// src/utils/createConversation.js
import { collection, addDoc, getDocs, query, where, limit } from "firebase/firestore";
import { firestore } from "../config/firebase";

/**
 * Creates a new conversation between two users.
 * @param {string} userId1 - ID of the first user.
 * @param {string} userId2 - ID of the second user.
 * @returns {Promise<string>} - The ID of the created conversation.
 */
const createConversation = async (userId1, userId2) => {
  // Ensure the conversationId is sorted to maintain consistency
  const sortedIds = [userId1, userId2].sort();
  const conversationId = `${sortedIds[0]}_${sortedIds[1]}`;
  
  const conversationRef = collection(firestore, "conversations");
  
  try {
    // Check if conversation already exists
    const existingConversations = await getDocs(
      query(
        conversationRef,
        where("conversationId", "==", conversationId),
        limit(1)
      )
    );
    
    if (!existingConversations.empty) {
      // Conversation already exists
      return existingConversations.docs[0].id;
    }
    
    // Create new conversation
    const docRef = await addDoc(conversationRef, {
      conversationId,
      participants: sortedIds,
      lastMessage: "",
      lastMessageTimestamp: null,
      environment: process.env.REACT_APP_ENV === "prod" ? "" : "_local",
    });
    
    return docRef.id;
  } catch (error) {
    console.error("Error creating conversation:", error);
    throw error;
  }
};

export default createConversation;
