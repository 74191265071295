import React, { useState } from 'react';
import './SignIn.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ForgotMyPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [linkSent, setLinkSent] = useState(false); // State to track if the reset link has been sent

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${apiUrl}/forgot_password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setMessage('A password reset link has been sent to your email.');
          setLinkSent(true); // Set linkSent to true to hide the button
        } else {
          setMessage('Error: ' + (data.message || 'An unexpected error occurred.'));
        }
      })
      .catch(error => {
        console.error('Error sending reset email:', error);
        setMessage('An error occurred. Please try again later.');
      });
  };

  return (
    <div className="sign-in-page">
      <div
        className="sign-in-left"
        style={{
          backgroundImage: `url('/images/register-left.jpeg')`,
        }}
      >
        <div className="sign-in-left-content">
          {/* Any additional content can go here */}
        </div>
      </div>
      <div className="sign-in-right">
        <h2 className="sign-in-title-forgot-pass">Forgot Your Password?</h2>
        <p className="sign-in-instructions">
          Spent a bit too much time at the Speakeasy and forgot your password? Not to worry.<br />
          Enter your email address below, and we'll send you a link to reset your password.
        </p>
        {!linkSent && (
          <form onSubmit={handleSubmit} className="sign-in-form">
            <div className="sign-in-form-group">
              <label htmlFor="email" className="sign-in-label">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                required
                className="sign-in-input"
              />
            </div>
            <button type="submit" className="register-get-started-button">Send Reset Link</button>
          </form>
        )}
        {message && <p className="sign-in-message">{message}</p>}
      </div>
    </div>
  );
};

export default ForgotMyPassword;
